import React, { useEffect, useState, Fragment, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import CardBody from "components/Card/CardBody.js";
import { DataGrid } from "@material-ui/data-grid";

import Card from "components/Card/Card.js";
import DeleteIcon from "@material-ui/icons/Delete";

import { adminManagement } from "./../../actions/admin";

import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import CardHeader from "components/Card/CardHeader.js";
import { Deletecategory } from './deleteCategory';
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  flexHeader: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
  },
  btnPrimary: {
    backgroundColor: "#b462c5",
    color: "#ffffff !important",
    padding: "7px 15px",
    fontSize: "12px",
    fontWeight: "700",
  },
};
const useStyles = makeStyles(styles);

const EnhancedTableToolbar = (props) => {
  const classes = useStyles();

  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    ></Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onChangeterm: PropTypes.func.isRequired,
};

export default function EnhancedTable() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [pageSize, setpageSize] = React.useState(10)
  var DeleteForward = useRef();
  const columns = [
    { field: "name", headerName: "Name", width: 300 },
    { field: "email", headerName: "Email", width: 300 },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      disableColumnMenu: true,
      width: 200,
      filterable: false,
      renderCell: (params) => {
        return (
          <Fragment>
            <Link to={"/eidt/" + params.row._id}>
              <EditIcon style={{ color: "#109ebf" }} />
            </Link>
            {/* <Link onClick={() => handleDelete(params.row._id)}>
              <DeleteIcon style={{ color: "#109ebf" }} />
            </Link> */}
            < a href="#/" onClick={() => DeleteForward.current.Season_Click(params.row._id)}>
              <DeleteIcon style={{ color: "#dc3545" }} />
            </a>
          </Fragment>
        );
      },
    },
  ];

  // const handleDelete = async (id) => {
  //   let data = {
  //     _id: id,
  //   };
  //   const { status } = await deleteAdmin(data);
  //   if (status) {
  //     window.location.reload();
  //     toastAlert("success", "Delete successfully");
  //   }
  // };

  const getData = async () => {
    const { status, result } = await adminManagement();
    if (status) {
      let respData = result.map((item, key) => {
        return {
          id: key,
          ...item,
        };
      });
      setData(respData);
    }
  };
  const handlePageSizeChange = async (params) => {
    setpageSize(params.pageSize)
  }
  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={classes.root}>
      <Deletecategory
        ref={DeleteForward}
        fetchCategory={getData}
      />
      <Paper className={classes.paper}>
        <Card>
          <CardHeader color="primary">
            <div className={classes.flexHeader}>
              <h4 className={classes.cardTitleWhite}>Admin List</h4>
              <Link to={"/add"} className={classes.btnPrimary}>
                Add Admin
              </Link>
            </div>
          </CardHeader>
          <CardBody>
            <div style={{ height: 600, width: "100%" }}>
              {data.length > 0 && (
                <DataGrid
                  pageSize={pageSize}
                  pagination
                  columns={columns}
                  rows={data}
                  rowsPerPageOptions={[10, 15]} onPageSizeChange={handlePageSizeChange}
                />
              )}
            </div>
          </CardBody>
        </Card>
      </Paper>
    </div>
  );
}
