// @material-ui/icons
import {
  Dashboard,
  LibraryBooks,
  List,
  Timeline,
  // Paid,
  Money,
  History,
  Domain,
  Security,
  Image,
  AccountBalance,
  AccountTree,
  AccountCircle,
  AssignmentInd,
  AssignmentLate,
  Settings,
  MoneyOff,
} from "@material-ui/icons";

const dashboardRoutes = [
  {
    id: "login",
    path: "/login",
    isSideMenu: false,
    isEdit: false,
    isRestricted: "common",
  },
  {
    id: "edit-profile",
    path: "/editprofile",
    isSideMenu: false,
    isEdit: false,
    isRestricted: "common",
  },

  /* Dashboard */
  {
    path: "#",
    id: "dashboard",
    name: "Dashboard",
    icon: Dashboard,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Dashboard",
        path: "/dashboard",
        icon: Dashboard,
      },
    ],
  },

  /* User */

  {
    path: "#",
    id: "category",
    name: "Admin Management",
    icon: LibraryBooks,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    isRestricted: "multi",
    child: [
      {
        name: "Admin management",
        path: "/adminManagement",
        icon: AssignmentInd,
      },
      {
        name: "Role management",
        path: "/bidlist",
        icon: AssignmentLate,
      },
      {
        name: "Fee management",
        path: "/adminfee",
        icon: MoneyOff,
      },
      {
        name: "Settings",
        path: "/settings",
        icon: Settings,
      },
    ],
  },
  {
    path: "#",
    id: "nftLists",
    name: "NFT Mint",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    isRestricted: "multi",
    child: [
      {
        name: "Mint",
        path: "/mintNft",
        icon: "Mint",
      },
      {
        name: "Mint lists",
        path: "/mint-NftLists",
        icon: Money,
      },
    ],
  },

  {
    path: "#",
    id: "token",
    name: "Category",
    icon: History,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Game",
        path: "/game-list",
        icon: Security,
      },
      {
        name: "Item type",
        path: "/itemTypeList",
        icon: Image,
      },
      {
        name: "Rarity",
        path: "/raritylist",
        icon: Domain,
      },
      {
        name: "Season",
        path: "/seasonlist",
        icon: Timeline,
      },
    ],
  },
  {
    path: "#",
    id: "transactionhistory",
    name: "Transaction History",
    icon: AccountBalance,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    isRestricted: "multi",
    child: [
      {
        name: "History",
        path: "/transaction-history",
        icon: AccountTree,
      },

    ],
  },
  {
    path: "#",
    id: "user",
    name: "Users",
    icon: AccountCircle,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Users",
        path: "/user-list",
        icon: AccountCircle,
      },
    ],
  },
];

export default dashboardRoutes;
