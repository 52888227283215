// import package
import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

// @material-ui/core 
import { makeStyles } from "@material-ui/core/styles";
import { InputLabel } from '@material-ui/core';
// import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { UpdateAdmin, EditAdmin } from './../../actions/admin'

import { toastAlert } from '../../lib/toastAlert';


const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

const initialFormValue = {
    'name': "",
    'email': "",
    'restriction': [], //restriction(path, isWriteAccess)
    'role': ""
}

const useStyles = makeStyles(styles);

const AdminEdit = (props) => {
    const classes = useStyles();
    const { userId } = useParams();
    const history = useHistory();
    // state
    const [formValue, setFormValue] = useState(initialFormValue);
    const { name, email, restriction, role } = formValue;

    // function
    const handleChange = (e) => {
        const { name, value } = e.target;
        let formData = { ...formValue, ...{ [name]: value } }
        setFormValue(formData)
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        let reqData = {
            "adminId": userId,
            name,
            email,
            restriction,
            role
        }
        try {
            const { status } = await UpdateAdmin(reqData);
            if (status) {
                toastAlert('success', "Updated Successfully", 'editAdmin')
                history.push('/adminManagement')

            }

        } catch (err) {

        }
    }



    const fetchAdminDetail = async () => {
        try {
            const { result } = await EditAdmin(userId)
            setFormValue(result);
        } catch (err) {

        }
    }

    useEffect(() => {
        fetchAdminDetail()
        //eslint-disable-next-line
    }, [])

    const back = () => {
        history.push('/adminManagement')
    }
    return (
        <div>
            <div className="page_header">
                <button className="btn btn_custom mr-3 mb-4" onClick={() => back()}>Back</button>
            </div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <form className={classes.form} noValidate onSubmit={handleFormSubmit}>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Edit Admin</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Name"
                                            name="name"
                                            value={name}
                                            onChange={handleChange}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Email"
                                            name="email"
                                            value={email}
                                            onChange={handleChange}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                    </GridItem>
                                    {role === 'superadmin' ?
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                labelText="role"
                                                name="role"
                                                value={role}

                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                            />
                                        </GridItem> : <FormControl variant="outlined" className={classes.formControl}>
                                            <InputLabel htmlFor="outlined-age-native-simple">Role</InputLabel>
                                            <Select
                                                native
                                                value={role}
                                                onChange={handleChange}
                                                label="Role"
                                                inputProps={{
                                                    name: 'role',
                                                    id: 'outlined-age-native-simple',
                                                }}
                                            >
                                                <option aria-label="None" value="" />
                                                <option >Co-owner</option>
                                                <option >Admin</option>
                                                <option>Publisher</option>
                                            </Select>
                                        </FormControl>}

                                </GridContainer>

                            </CardBody>
                            <CardFooter>
                                <Button color="primary" type="submit">Update</Button>
                            </CardFooter>
                        </form>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}

export default AdminEdit;