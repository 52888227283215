// import package
import React, { useState, useEffect } from "react";
import isEmpty from "lib/isEmpty";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import CryptoJS from "crypto-js";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
// import action
import { UpdateSettings, GetSettings } from "actions/admin";
// import lib
import { toastAlert } from "../../lib/toastAlert";
import Web3 from "web3";

import config from "../../lib/config"

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const initialFormValue = {
  wallet: "",
  privatekey: ""
};

var cryptoKey = config.cryptoKey;
var cryptoIv = config.cryptoIv;

const useStyles = makeStyles(styles);

const Settings = (props) => {
  const classes = useStyles();
  const web3 = new Web3(window.web3.currentProvider)
  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const { wallet, privatekey } = formValue;
  const [ValidateError, setValidationError] = useState({})

  const getSetting = async () => {
    try {
      const { status, result } = await GetSettings();
      if (status) {
        var decrypted = CryptoJS.AES.decrypt(result.privatekey, cryptoKey, {
          iv: cryptoIv,
        });
        var keyy = decrypted.toString(CryptoJS.enc.Utf8);
        result.privatekey = keyy;
        setFormValue(result)
      } else { }
    } catch (err) { }
  }


  useEffect(() => {
    getSetting()
    //eslint-disable-next-line
  }, [])
  // function
  const handleChange = (e) => {
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    setValidationError("")
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    let errors = {};
    if (isEmpty(wallet)) {
      errors.wallet = "Wallet field is required"
    }
    if (isEmpty(privatekey)) {
      errors.privatekey = "Private key field is required"
    }
    if(!web3.utils.isAddress(wallet)){
      errors.wallet = "Please Enter valid wallet address"
    }
    try{
   var {address} = web3.eth.accounts.privateKeyToAccount(privatekey);
    }catch(err){
      errors.privatekey = "Please Enter valid private key"
    }
   if(!web3.utils.isAddress(address)){
    errors.privatekey = "Please Enter valid private key"
  }
    if (!isEmpty(errors)) {
      return setValidationError(errors)
    }
    
    let data = {
      wallet: wallet,
      privatekey: privatekey
    };
    const { status, error } = await UpdateSettings(data);
    if (status) {
      toastAlert("success", "Updated successfully", "itemadd");
    }
    else {
      toastAlert('error', error, "itemadd")
    }
  };

  return (
    <div>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleFormSubmit}
            >
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Settings</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <div className="container">
                    <div className="row wow fadeIn form-border">
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Wallet Address"
                          name="wallet"
                          value={wallet}
                          onChange={handleChange}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                        {
                          ValidateError && ValidateError.wallet && <p className={'error-message'}>{ValidateError.wallet}</p>
                        }
                      </GridItem>
                      <label className="primary_label"> </label>
                    </div>
                    <div className="row wow fadeIn form-border">
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Private Key"
                          name="privatekey"
                          value={privatekey}
                          onChange={handleChange}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                        {
                          ValidateError && ValidateError.privatekey && <p className={'error-message'}>{ValidateError.privatekey}</p>
                        }
                      </GridItem>
                      <label className="primary_label"> </label>
                    </div>
                  </div>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">
                  Update
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default Settings;
