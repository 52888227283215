import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Link } from "react-router-dom";

// import material UI
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from '@material-ui/data-grid';
import { Paper } from '@material-ui/core';
import {
    Edit as EditIcon,
    Delete as DeleteIcon,
} from '@material-ui/icons';

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { getCategoryList } from 'actions/admin';
import { DeleteSeason } from './deleteSeason';


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    flexHeader: {
        display: "flex !important",
        justifyContent: "space-between !important",
        alignItems: "center !important"
    },
    btnPrimary: {
        backgroundColor: "#b462c5",
        color: "#ffffff !important",
        padding: "7px 15px",
        fontSize: "12px",
        fontWeight: "700"
    }
};
const useStyles = makeStyles(styles);


const SeasonList = (props) => {
    const classes = useStyles();

    // state
    const [data, setData] = useState([])
    const [pageSize, setpageSize] = React.useState(10)
    var DeleteForward = useRef();

    const columns = ([
        { field: 'category', headerName: 'Season', width: 300 },
        {
            field: 'Action',
            headerName: 'Action',
            width: 300,
            sortable: false,
            disableColumnMenu: true,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Fragment>
                        <Link to={'/editseason/' + params.row._id}>
                            <EditIcon style={{ color: "#109ebf" }} />
                        </Link>
                        <a href="#/" onClick={() => DeleteForward.current.Season_Click(params.row._id)}>
                            <DeleteIcon style={{ color: "#dc3545" }} />
                        </a>
                    </Fragment>
                )
            }
        },
    ])

    // function
    const fetchCategory = async () => {
        try {
            const { status, result } = await getCategoryList({ categorytype: "Season" });
            if (status) {
                let respData = result.map((item, key) => {
                    return {
                        id: key,
                        ...item
                    }
                })
                setData(respData)
            } else { }
        } catch (err) { }
    }
    const handlePageSizeChange = async (params) => {
        setpageSize(params.pageSize)
    }
    useEffect(() => {
        fetchCategory()
    }, [])

    return (
        <div className={classes.root}>
            <DeleteSeason
                ref={DeleteForward}
                fetchCategory={fetchCategory}
            />
            <Paper className={classes.paper}>
                <Card>
                    <CardHeader color="primary">
                        <div className={classes.flexHeader}>
                            <h4 className={classes.cardTitleWhite}>Season List</h4>
                            <Link to={'/addseason'} className={classes.btnPrimary}>Add Season</Link>
                        </div>
                    </CardHeader>
                    <CardBody>
                        {data && data.length > 0 &&
                            <div style={{ height: 600, width: '100%' }}>
                                <DataGrid pageSize={pageSize} pagination rows={data} columns={columns} rowsPerPageOptions={[10, 15]} onPageSizeChange={handlePageSizeChange} />
                            </div>
                        }
                    </CardBody>
                </Card>
            </Paper>
        </div >
    )
}

export default SeasonList;