// import package
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// @material-ui/core 
import { makeStyles } from "@material-ui/core/styles";
import { InputLabel } from '@material-ui/core';

// import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import { addAdmin } from "../../../src/actions/admin"
// import lib
//import routes from '../../routes';
import { toastAlert } from '../../lib/toastAlert';


const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
};



const initialFormValue = {
    'name': "",
    'email': "",
    'password': "",
    'role': "",
    'restriction': []  //restriction(path, isWriteAccess)
}

const useStyles = makeStyles(styles);

const AdminAdd = (props) => {
    const classes = useStyles();
    const history = useHistory();

    // state
    const [formValue, setFormValue] = useState(initialFormValue);


    const { name, email, password, restriction, role } = formValue;

    // function
    const handleChange = (e) => {
        const { name, value } = e.target;
        let formData = { ...formValue, ...{ [name]: value } }
        setFormValue(formData)
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        function validateEmail(email) {
            var re = /\S+@\S+\.\S+/;
            return re.test(email);
        }
        let reqData = {
            name: name,
            email: email,
            password: password,
            role: role,
            restriction: restriction
        }
        if (reqData.name === "") {
            toastAlert('error', "Name field can't be empty")
        } else if (reqData.email === "") {
            toastAlert('error', "Email field can't be empty")
        } else if (!validateEmail(reqData.email)) {
            toastAlert('error', "Enter valid email address")
        }
        else if (reqData.password === "") {
            toastAlert('error', "Password field can't be empty")
        } else if (reqData.role === "") {
            toastAlert('error', "Select role")
        } else {
            try {
                const { status } = await addAdmin(reqData);
                if (status) {
                    toastAlert("success", "Added successfully")
                    history.push('/adminManagement')
                }

            } catch (err) {

            }
        }

    }

    // useEffect(() => {
    //     const getalldata = async () => {
    //         try {
    //             if (routes && routes.length > 0) {
    //                 let commonData = []
    //                 routes.filter((el) => {
    //                     if (el.isRestricted === 'common') {
    //                         commonData.push({
    //                             'path': el.path,
    //                             'isWriteAccess': false
    //                         })

    //                     }
    //                     return true
    //                 })

    //                 if (commonData && commonData.length > 0) {
    //                     let formData = {
    //                         ...formValue, ...{
    //                             'restriction': commonData
    //                         }
    //                     }
    //                     if(formData){
    //                         setFormValue(formData);
    //                     }

    //                 }

    //             }
    //             else { }
    //         }
    //         catch(err){

    //         }

    //     }
    // // getalldata()
    // })
    const back = () => {
        history.push('/adminManagement')
    }
    return (
        <div>
            <div className="page_header">
                <button className="btn btn_custom mr-3 mb-4" onClick={() => back()}>Back</button>
            </div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <form className={classes.form} noValidate onSubmit={handleFormSubmit}>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Add New Admin</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Name"
                                            name="name"
                                            value={name}
                                            onChange={handleChange}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />

                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Email"
                                            name="email"
                                            value={email}
                                            onChange={handleChange}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />

                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Password"
                                            type="password"
                                            name="password"
                                            value={password}
                                            onChange={handleChange}
                                            formControlProps={{
                                                fullWidth: true
                                            }}

                                        />

                                    </GridItem>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel htmlFor="outlined-age-native-simple">Role</InputLabel>
                                        <Select
                                            native
                                            value={role}
                                            onChange={handleChange}
                                            label="Role"
                                            inputProps={{
                                                name: 'role',
                                                id: 'outlined-age-native-simple',
                                            }}
                                        >
                                            <option aria-label="None" value="" />
                                            <option >Co-owner</option>
                                            <option >Admin</option>
                                            <option>Publisher</option>
                                        </Select>
                                    </FormControl>
                                </GridContainer>
                                {/* <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <InputLabel>Restriction</InputLabel>
                                        {renderRestrictionList()}

                                    </GridItem>
                                </GridContainer> */}
                            </CardBody>
                            <CardFooter>
                                <Button color="primary" type="submit">ADD</Button>
                            </CardFooter>
                        </form>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}

export default AdminAdd;