// import package
import React, { useState, useEffect } from "react";
import isEmpty from "lib/isEmpty";
import Web3 from "web3";
import SingleABI from "../../ABI/BEP721"
import MultipleABI from "../../ABI/BEP1155"
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import CryptoJS from "crypto-js";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
// import action
import { UpdateSettings, GetSettings } from "actions/admin";
// import lib
import { toastAlert } from "../../lib/toastAlert";

import config from "../../lib/config"

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const initialFormValue = {
  single: "",
  multiple: "",
};

var cryptoKey = config.cryptoKey;
var cryptoIv = config.cryptoIv;

const useStyles = makeStyles(styles);

const AdminFee = (props) => {
  const classes = useStyles();

  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const { single, multiple } = formValue;
  const [ValidateError, setValidationError] = useState({})
  const [address, setaddress] = useState()

  const getSetting = async () => {
    console.log('*****************>>>>>>')
    try {
      console.log('11111111111111')
      let web3 = new Web3(window.web3.currentProvider);
      await window.ethereum.request({ method: 'eth_requestAccounts' })
      // const accounts = await web3.eth.requestAccounts();
      var accounts = await web3.eth.getAccounts();
      console.log('22222222222222')
      // await window.ethereum.request({ method: 'eth_requestAccounts' })
      let singleContract = new web3.eth.Contract(SingleABI, config.singlesmartContract);
      let multipleContract = new web3.eth.Contract(MultipleABI, config.multiplesmartContract);
      let singlefee = await singleContract.methods.getServiceFee().call()
      let multiplefee = await multipleContract.methods.getServiceFee().call()
      console.log(multiplefee, 'multiplefeemultiplefee')
      let formData = {
        single: singlefee / 1e18,
        multiple: multiplefee / 1e18
      }
      console.log(formData, "formdata")
      setaddress(accounts[0]);
      setFormValue(formData)
    } catch (err) {
      console.log(err, "error")
    }
  }


  useEffect(() => {
    getSetting()
    //eslint-disable-next-line
  }, [])
  // function
  const handleChange = (e) => {
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    setValidationError("")
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    let errors = {};
    if (isEmpty(single)) {
      errors.single = "single fee field is required"
    }

    if (!isEmpty(errors)) {
      return setValidationError(errors)
    }
    if (config.owneraddress != address) {
      toastAlert('error', "please use owner address", "wallet");
      return;
    }
    await window.ethereum.request({ method: 'eth_requestAccounts' })
    let web3 = new Web3(window.web3.currentProvider)
    let data = web3.utils.toWei(single)
    let singleContract = new web3.eth.Contract(SingleABI, config.singlesmartContract);
    try {
      let result = await singleContract.methods.serviceFunction(data).send({ from: address })
      if (result) {
        toastAlert("success", "Updated successfully", "wallet");
      }
    } catch (error) {
      console.log(error, "error")
      toastAlert('error', "update failed", "wallet");
    }
  };

  const handleFormSubmitmultiple = async (e) => {
    e.preventDefault();
    let errors = {};
    if (isEmpty(multiple)) {
      errors.multiple = "multiple fee field is required"
    }

    if (!isEmpty(errors)) {
      return setValidationError(errors)
    }
    if (config.owneraddress != address) {
      toastAlert('error', "please use owner address", "wallet");
      return;
    }
    await window.ethereum.request({ method: 'eth_requestAccounts' })
    let web3 = new Web3(window.web3.currentProvider)
    let data = web3.utils.toWei(multiple)
    let multipleContract = new web3.eth.Contract(MultipleABI, config.multiplesmartContract);
    try {
      let result = await multipleContract.methods.serviceFunction(data).send({ from: address })
      if (result) {
        toastAlert("success", "Updated successfully", "wallet");
      }
    } catch (error) {
      console.log(error, "error")
      toastAlert('error', "update failed", "wallet");
    }
  };

  return (
    <div>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleFormSubmit}
            >
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Single Contract Admin Fee</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <div className="container">
                    <div className="row wow fadeIn form-border">
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="single contract fee"
                          name="single"
                          value={formValue.single}
                          onChange={handleChange}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                        {
                          ValidateError && ValidateError.single && <p className={'error-message'}>{ValidateError.single}</p>
                        }
                      </GridItem>
                      <label className="primary_label"> </label>
                    </div>
                  </div>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">
                  Update
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleFormSubmitmultiple}
            >
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Multiple Contract Admin Fee</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <div className="container">
                    <div className="row wow fadeIn form-border">
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Multiple contract fee"
                          name="multiple"
                          value={formValue.multiple}
                          onChange={handleChange}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                        {
                          ValidateError && ValidateError.multiple && <p className={'error-message'}>{ValidateError.multiple}</p>
                        }
                      </GridItem>
                      <label className="primary_label"> </label>
                    </div>
                  </div>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">
                  Update
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default AdminFee;
