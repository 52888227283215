import React, { useEffect, useState, Fragment } from 'react';
// import material UI
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from '@material-ui/data-grid';
import { Paper } from '@material-ui/core';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Getbuylist } from "actions/admin"

import config from "../../lib/config"
import {
    LibraryBooks as LibraryBook,

} from '@material-ui/icons';
import Tooltip from "@material-ui/core/Tooltip";
// import action
import { convert, momentFormat } from "../../helper/Helper"
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    flexHeader: {
        display: "flex !important",
        justifyContent: "space-between !important",
        alignItems: "center !important"
    },
    btnPrimary: {
        backgroundColor: "#b462c5",
        color: "#ffffff !important",
        padding: "7px 15px",
        fontSize: "12px",
        fontWeight: "700"
    }
};
const useStyles = makeStyles(styles);


const TransactionHistory = (props) => {
    const classes = useStyles();

    // state
    const [data, setData] = useState([])
    const [pageSize, setpageSize] = React.useState(10)
    const columns = ([
        { field: 'owner', headerName: 'From', width: 200 },
        { field: 'from', headerName: 'To', width: 200 },
        {
            field: 'price', headerName: 'Price (Matic)', width: 200,
            renderCell: (params) => {
                return (
                    convert(params.row.price)
                )
            }
        },
        { field: 'quantity', headerName: 'Quantity', width: 150 },
        {
            field: 'type', headerName: 'Type', width: 150,
            renderCell: (params) => {
                return (
                    params.row.type ? params.row.type : '-'
                )
            }
        },
        {
            field: 'date', headerName: 'Date', width: 200,filterable: false,
            renderCell: (params) => {
                return (momentFormat(params.row.date))

            }
        },
        {
            field: 'txid',
            headerName: 'Hash',
            sortable: false,
            disableColumnMenu: true,
            width: 150,
            filterable:false,
            renderCell: (params) => {
                return (
                    <Fragment>
                        <Tooltip title="view hash"><a href={config.mumbaipolygon + 'tx/' + params.row.txid} target="_blank" rel="noopener noreferrer">

                            <LibraryBook style={{ color: "#109ebf" }} />
                        </a></Tooltip>
                    </Fragment>
                )
            }
        },
    ])

    // function
    const getbuytransaction = async () => {
        const { result } = await Getbuylist()
        if (result) {

            let respData = result.map((item, key) => {
                return {
                    id: key,
                    ...item
                }
            })
            setData(respData)
        }
        else {
        }
    }

    const handlePageSizeChange = async (params) => {
        setpageSize(params.pageSize)
    }
    useEffect(() => {
        getbuytransaction()
    }, [])

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Card>
                    <CardHeader color="primary">
                        <div className={classes.flexHeader}>
                            <h4 className={classes.cardTitleWhite}>Buy Transaction</h4>
                        </div>
                    </CardHeader>
                    <CardBody>
                        {data && data.length > 0 &&
                            <div style={{ height: 600, width: '100%' }}>
                                <DataGrid pageSize={pageSize} pagination rows={data} columns={columns} rowsPerPageOptions={[10, 15]} onPageSizeChange={handlePageSizeChange} />
                            </div>
                        }
                    </CardBody>
                </Card>
            </Paper>
        </div >
    )
}

export default TransactionHistory;