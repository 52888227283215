import React, { Fragment, } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import CardBody from "components/Card/CardBody.js";
import { DataGrid } from "@material-ui/data-grid";

import Card from "components/Card/Card.js";


// import { adminManagement } from "./../../actions/admin";


import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import CardHeader from "components/Card/CardHeader.js";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  flexHeader: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
  },
  btnPrimary: {
    backgroundColor: "#b462c5",
    color: "#ffffff !important",
    padding: "7px 15px",
    fontSize: "12px",
    fontWeight: "700",
  },
};
const useStyles = makeStyles(styles);

const EnhancedTableToolbar = (props) => {
  const classes = useStyles();

  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    ></Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onChangeterm: PropTypes.func.isRequired,
};

export default function EnhancedTable() {
  const classes = useStyles();
  // const [data, setData] = useState([]);
  const [pageSize, setpageSize] = React.useState(10)

  // const getAll = async (search = {}) => {
  //   let filterData = search;
  //   if (filterData.search == "") {
  //     filterData = {};
  //   }

  //   var res = await getAllcategory(filterData);
  //   if (res && res.result) {
  //     setUser(res.result.data);
  //   }
  // };
  const columns = [
    { field: "role", headerName: "Role", width: 300 },
    // { field: "name", headerName: "Name", width: 300 },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (params) => {
        return (
          <Fragment>
            <Link to={"/editRole/" + params.row.role}>
              <EditIcon style={{ color: "#109ebf" }} />
            </Link>
          </Fragment>
        );
      },
    },
  ];
  const rows = [
    { id: 1, role: "Co-owner", },
    { id: 2, role: "Admin", },
    { id: 3, role: "Publisher", },
  ]


  // const handleDelete = (id) => {
  //   let data = {
  //     _id: id,
  //   };
  //   const { status, messages, result } = deleteAdmin(data);

  //   if (status == true) {
  //     toastAlert("Delete successfully");
  //   }
  // };

  // const getData = async () => {
  //   const { status, result } = await adminManagement();

  //   if (status === true) {
  //     let respData = result.map((item, key) => {
  //       return {
  //         id: key,
  //         ...item,
  //       };
  //     });
  //     setData(respData);
  //   }
  // };
  const handlePageSizeChange = async (params) => {
    setpageSize(params.pageSize)
  }
  // useEffect(() => {
  //   getData();
  // }, []);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Card>
          <CardHeader color="primary">
            <div className={classes.flexHeader}>
              <h4 className={classes.cardTitleWhite}>Role Management List</h4>
            </div>
          </CardHeader>
          <CardBody>
            <div style={{ height: 600, width: "100%" }}>
              {/* {data.length > 0 && ( */}
              <DataGrid
                pageSize={pageSize}
                pagination
                columns={columns}
                rows={rows}
                rowsPerPageOptions={[10, 15]} onPageSizeChange={handlePageSizeChange}
              />
              {/* )} */}
            </div>
          </CardBody>
        </Card>
      </Paper>
    </div>
  );
}
