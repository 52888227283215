// import package
import React, { useState, useEffect, Fragment } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import $ from "jquery"
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
// import lib
import { toastAlert } from "../../../lib/toastAlert";
import isEmpty from "lib/isEmpty";
import { EditCategory } from "actions/admin";
import { UpdateCategoryAction } from "actions/admin";
import config from "../../../lib/config"
import { getCategoryList } from 'actions/admin';
const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
    },
};

const initialFormValue = {
    name: "",
    image: ""
};

const useStyles = makeStyles(styles);

const RarityEdit = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams();
    const rarityid = id;
    // state
    const [formValue, setFormValue] = useState(initialFormValue);
    const { name, image } = formValue;
    const [defaultImage, setDefaultImage] = useState(false);
    const [Logo, setLogo] = useState();
    const [selectedImage, setSelectedImage] = useState(null);
    const [values, setValues] = useState({ val: [{ des: "" }] });
    const [ValidateError, setValidationError] = useState({})
    const [itemTypes, setitemTypes] = useState({ id: "", category: "" });
    const [itemList, setitemList] = useState([]);


    const fetchCategory = async () => {
        try {
            const { status, result } = await getCategoryList({ categorytype: "Itemtype" });
            if (status) {
                let respData = result.map((item, key) => {

                    return {
                        id: key,
                        ...item
                    }
                })
                setitemList(respData)
            } else { }
        } catch (err) { }
    }


    useEffect(() => {
        getRaritylist()
        fetchCategory()
        //eslint-disable-next-line
    }, [])

    // function
    const handleChange = (e) => {
        const { name, value } = e.target;
        let formData = { ...formValue, ...{ [name]: value } };
        setFormValue(formData);
        //setItem(value)
        setValidationError("")
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        let errors = {};
        if (isEmpty(name)) {
            errors.name = "Name field is required"
        }
        if (!defaultImage && (isEmpty(Logo))) {
            if (isEmpty(selectedImage)) {
                errors.selectedImage = "Image field is required"
            }
        }
        if (values && values.val) {
            for (var i = 0; i < values.val.length; i++) {
                if (isEmpty(values.val[i].title)) {
                    errors.values = "Properties field is required"
                }
            }
        }
        if (!isEmpty(errors)) {
            return setValidationError(errors)
        }

        let formData = new FormData();
        formData.append("image", selectedImage);
        formData.append("category", name);
        formData.append("attributes", JSON.stringify(values.val));
        formData.append("categorytype", "Game");
        formData.append("id", id);
        formData.append("itemTypes", JSON.stringify(itemTypes));

        const { status, error } = await UpdateCategoryAction(formData);
        if (status) {
            toastAlert("success", "updated successfully", "game update");
            history.push('/game-list')
        }
        else {
            toastAlert("error", error, "game add");
        }
    };

    const getRaritylist = async () => {
        const data = {
            id: rarityid
        }
        const { result } = await EditCategory(data)
        if (result) {
            var logoPrvw = config.baseUrl + "/category/" + result.logo;
            setLogo(logoPrvw);
            // setSelectedImage(result.logo);
            let catId = (result && result.gamesubcategory && result.gamesubcategory.id) ? result.gamesubcategory.id : ""
            let catVal = (result && result.gamesubcategory && result.gamesubcategory.category) ? result.gamesubcategory.category : ""
            setitemTypes({ id: catId, category: catVal })
            let formData = { ...formValue, ...{ name: result.category } }
            setFormValue(formData)
            setValues({ val: result.attributes });
        }
    }

    const selectprofileFileChange = async (event) => {
        if (event.target.files && event.target.files[0]) {
            var file = event.target.files[0];
            var validExtensions = [
                "jpg",
                "png"
            ];
            if (file && file.name) {
                var fileName = file.name;
                var fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);
                if ($.inArray(fileNameExt, validExtensions) === -1) {
                    setDefaultImage(false);
                    getRaritylist()
                    toastAlert("error", "Only these file types are accepted : " +
                        validExtensions.join(", "), "game add");
                    return false;
                } else {
                    setSelectedImage(file);
                    setLogo(URL.createObjectURL(event.target.files[0]));
                    setDefaultImage(true);
                    setValidationError("")
                }
            } else {
                setDefaultImage(false);
                setLogo("")
                toastAlert("error", "Only these file types are accepted : " +
                    validExtensions.join(", "), "game add");
            }
        }
    };

    function handleChanges(event) {
        let errors = {};

        let checkVal = values && values.val.length > 0 && values.val.map(item => item.title)
        if (checkVal.includes(event.target.value)) {
            errors.values = "Properties should not be same"
            return setValidationError(errors)
        } else {
            let vals = [...values.val];
            vals[this].title = event.target.value;
            setValues({ val: vals });


        }
        setValidationError("")

    }


    function createInputs() {
        return values.val.map((el, i) => (

            <Fragment key={i}>

                <div className="col-md-3">
                    <input
                        type="text"

                        name="item_royalties"
                        id="item_royalties"
                        className="form-control mb-3"
                        placeholder="e.g. Name"
                        onChange={handleChanges.bind(i)}
                        value={(el && el.title) ? el.title : ""}
                        autoComplete="off"
                    />
                    {
                        ValidateError && ValidateError.values && <p className={'error-message'}>{ValidateError.values}</p>
                    }
                </div>
                {i !== 0 && (
                    <div>
                        <i onClick={() => removeClick(i)} className="fa fa-times deleteicon"></i>
                    </div>
                )}
            </Fragment>
        ));
    }

    function removeClick(i) {
        let vals = [...values.val];
        vals.splice(i, 1);
        setValues({ val: vals });
    }

    const addClick = async (e) => {
        e.preventDefault();
        setValues({ val: [...values.val, { des: "" }] });
    };


    const handleChangeItem = (e) => {
        const { value } = e.target;
        var index = itemList.findIndex(val => val.category === value);
        if (index !== -1) {
            setitemTypes({ id: itemList[index]._id, category: itemList[index].category })
            setValidationError("")
        }
    }

    return (
        <div>
            <div className="page_header">
                <Link className="btn btn_custom mr-3 mb-4" to="/game-list">Back</Link>
            </div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <form
                            className={classes.form}
                            noValidate
                            onSubmit={handleFormSubmit}
                        >
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Edit game</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <div className="container">
                                        <div className="row wow fadeIn form-border">
                                            <GridItem xs={12} sm={12} md={12}>
                                                <CustomInput
                                                    labelText="Game"
                                                    name="name"
                                                    value={name}
                                                    onChange={handleChange}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                />
                                                {
                                                    ValidateError && ValidateError.name && <p className={'error-message'}>{ValidateError.name}</p>
                                                }
                                            </GridItem>
                                            <label className="primary_label"> </label>

                                        </div>

                                        <div className="col-lg-5">
                                            <div className="d-create-file">

                                                <div className="uploadCustomFile">
                                                    <input
                                                        type="file"
                                                        id="photo"
                                                        required={true}
                                                        name="image"
                                                        value={image}
                                                        className="btn-main"
                                                        onChange={selectprofileFileChange}
                                                    />
                                                    {
                                                        ValidateError && ValidateError.selectedImage && <p className={'error-message'}>{ValidateError.selectedImage}</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        {Logo && Logo !== "" &&
                                            <>
                                                <div className="col-lg-3">
                                                    <div className="createPreview profileImgPreview">
                                                        <h1 className="primary_label">Image preview</h1>
                                                        <div className="nft__item">
                                                            <img src={Logo} alt="game"></img>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={12}>
                                                <TextField
                                                    className="add_game_text"
                                                    id="outlined-select-currency"
                                                    select
                                                    label="Select Item Type"
                                                    value={itemTypes.category}
                                                    name="itemtypevalue"
                                                    onChange={handleChangeItem}
                                                // helperText="Please select Category"
                                                >
                                                    {itemList &&
                                                        itemList.length > 0 ?
                                                        itemList.map((option) => (
                                                            <MenuItem key={option.category} id={option.category} value={option.category}>
                                                                {option.category}
                                                            </MenuItem>
                                                        )) : <MenuItem ></MenuItem>}

                                                </TextField>
                                                {
                                                    ValidateError && ValidateError.itemTypes && <p className={'error-message'}>{ValidateError.itemTypes}</p>
                                                }
                                            </GridItem>
                                        </GridContainer>

                                        <label className="primary_label"> </label>
                                        <div className="row">
                                            {createInputs()}
                                        </div>
                                        <button
                                            type="button"
                                            onClick={addClick}
                                            className="btn btn-outline-danger"
                                        >
                                            {" "}
                                            Add Properties
                                        </button>

                                    </div>
                                </GridContainer>
                            </CardBody>
                            <CardFooter>
                                <div className="pl-3">
                                    <Button color="primary" type="submit">
                                        Update
                                    </Button>
                                </div>
                            </CardFooter>
                        </form>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
};

export default RarityEdit;
