// import package
import React, { useEffect, useState } from "react";
import ReactTextTransition from "react-text-transition";

// import componet
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";
// import action
import {
  getDashboardCount,
} from "../../actions/dashboard";
import { Link } from "react-router-dom";

const DashboardCount = (props) => {
  // props
  const { classes } = props;

  // state
  const [SingleTokenCount, setSingleTokenCount] = useState(0);
  const [MultipleTokenCount, setMultipleTokenCount] = useState(0);
  const [SalesCount, setSalesCount] = useState(0);
  const [UserCount, setUserTokenCount] = useState(0);

  // function
  const fetchData = async () => {
    const { saleCnt, singlenft, multiplenft, usercnt } = await getDashboardCount();
    setSingleTokenCount(singlenft)
    setMultipleTokenCount(multiplenft)
    setSalesCount(saleCnt)
    setUserTokenCount(usercnt);
  };



  useEffect(() => {
    fetchData();
  }, []);
  return (
    <GridContainer>
      <GridItem xs={12} sm={6} md={3}>
        <Link to="#"></Link>
        {/* <a href={config.frontUrl + "/wownft/TokenList"}> */}
        <Card>
          <CardHeader color="info" stats icon>
            <CardIcon color="info">
              {/* <Done /> */}
              <img src={require("../../assets/img/coin.png")} width="56px" height="56px" alt="nft"></img>
            </CardIcon>
            <p className={classes.cardCategory} style={{ color: "#33aec7" }}>
              Single 721
            </p>
            <h3 className={classes.cardTitle}>
              {`${SingleTokenCount}`.split("").map((n, i) => (
                <ReactTextTransition
                  key={i}
                  children={n}
                  delay={i * 100}
                  className="big"
                  overflow
                  inline
                />
              ))}
            </h3>
          </CardHeader>
          <CardFooter stats></CardFooter>
        </Card>
        {/* </a> */}
      </GridItem>
      <GridItem xs={12} sm={6} md={3}>
        {/* <a href={config.frontUrl + "/wownft/TokenList"}> */}
        <Card>
          <CardHeader color="primary" stats icon>
            <CardIcon color="primary">
              
              <img src={require("../../assets/img/digital.png")} width="56px" height="56px" alt="nft"></img>
            </CardIcon>
            <p className={classes.cardCategory} style={{ color: "#701a85" }}>
              Multiple 1155
            </p>
            <h3 className={classes.cardTitle}>
              {`${MultipleTokenCount}`.split("").map((n, i) => (
                <ReactTextTransition
                  key={i}
                  children={n}
                  delay={i * 100}
                  className="big"
                  overflow
                  inline
                />
              ))}
            </h3>
          </CardHeader>
          <CardFooter stats></CardFooter>
        </Card>
        {/* </a> */}
      </GridItem>
      <GridItem xs={12} sm={6} md={3}>
        {/* <a href={config.frontUrl + "/wownft/userlist"}> */}
        <Card>
          <CardHeader color="success" stats icon>
            <CardIcon color="success">
              {/* <People /> */}
              <img src={require("../../assets/img/vr.png")} width="56px" height="56px" alt="nft"></img>
            </CardIcon>
            <p className={classes.cardCategory} style={{ color: "#205222" }}>
              Total Users
            </p>
            <h3 className={classes.cardTitle}>
              {`${UserCount}`.split("").map((n, i) => (
                <ReactTextTransition
                  key={i}
                  children={n}
                  delay={i * 100}
                  className="big"
                  overflow
                  inline
                />
              ))}
            </h3>
          </CardHeader>
          <CardFooter stats></CardFooter>
        </Card>
        {/* </a> */}
      </GridItem>
      <GridItem xs={12} sm={6} md={3}>
        {/* <a href={config.frontUrl + "/wownft/PurchaseHistory"}> */}
        <Card>
          <CardHeader color="warning" stats icon>
            <CardIcon color="warning">
              {/* <ShoppingCart /> */}
              <img src={require("../../assets/img/exchange.png")} width="56px" height="56px" alt="nft"></img>
            </CardIcon>
            <p className={classes.cardCategory} style={{ color: "#a56b18" }}>
              Total Sales
            </p>
            <h3 className={classes.cardTitle}>
              {`${SalesCount}`.split("").map((n, i) => (
                <ReactTextTransition
                  key={i}
                  children={n}
                  delay={i * 100}
                  className="big"
                  overflow
                  inline
                />
              ))}
            </h3>
          </CardHeader>
          <CardFooter stats></CardFooter>
        </Card>
        {/* </a> */}
      </GridItem>
    </GridContainer>
  );
};

export default DashboardCount;
