import moment from "moment";
import isEmpty from "lib/isEmpty";
export async function numberFloatOnly(value) {
    const regxFormat = /^['']?\d*(?:[.]\d*)?$/;
    var result = regxFormat.test(value)
    return result;
}

export async function numberOnly(value) {
    const regxFormat = /^['']?\d*(?:['']\d*)?$/;
    var result = regxFormat.test(value)
    return result;
}

export function convert(n) {
    try {
        var sign = +n < 0 ? "-" : "",
            toStr = n.toString();
        if (!/e/i.test(toStr)) {
            return n;
        }
        var [lead, decimal, pow] = n.toString()
            .replace(/^-/, "")
            .replace(/^([0-9]+)(e.*)/, "$1.$2")
            .split(/e|\./);
        return +pow < 0
            ? sign + "0." + "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
            : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))) : (decimal.slice(0, +pow) + "." + decimal.slice(+pow)))
    } catch (err) {
        return 0;
    }
}

export async function isNumberCheck(amount) {
    var numberVal = amount;
    var convertamt = (isFinite(numberVal) && numberVal > 0 && numberVal !== "Infinity") ? numberVal : 0;
    return convertamt;
}

export function formatAddress(address) {
    try {
        var addr = address.substring(0, 16);
        var addr1 = address.substring(30, 42);
        var concat = addr + "...." + addr1;
        return concat;
    } catch (err) {
        return "";
    }
}

export function dateFormat(date, format) {

    if (date && date !== '' && format && format !== '') {
        var cDate = moment(date).format(format);
        return cDate;
    } else {
        return '-';
    }
}
export const momentFormat = (dateTime, format = 'MMM,Do YYYY, hh:mm A') => {
    try {
      if (!isEmpty(dateTime)) {
        let newDateTime = new Date(dateTime)
        return moment(newDateTime).format(format)
      }
      return ''
    } catch (err) {
      return ''
    }
  }
  export const onlydate = (dateTime, format = 'MMM,Do YYYY') => {
    try {
      if (!isEmpty(dateTime)) {
        let newDateTime = new Date(dateTime)
        return moment(newDateTime).format(format)
      }
      return ''
    } catch (err) {
      return ''
    }
  }