// import axios
import axios from "axios";

// import lib
import config from "../lib/config";
import { getAuthToken } from "../lib/localStorage";

axios.defaults.headers.common["Authorization"] = getAuthToken();

export const Bidding = async () => {
  try {
    let respData = await axios({
      method: "GET",
      url: `${config.baseUrl}/v2/routesAdmin/bidding`,
    });
    return {
      status: true,
      result: respData.data.data,
    };
  } catch (err) {
    
  }
};

export const createNftListTdyAction = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/routesAdmin/createnft-latest`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getBidderCount = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/routesAdmin/bidderCount`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getChartData = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/routesAdmin/getchartdata`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getCreateNftLists = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/routesAdmin/getCreateList`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getDashboardCount = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesAdmin/get-all-counts`,
    });
    return {
      status: "success",
      loading: false,
      saleCnt: respData.data.saleCnt,
      singlenft: respData.data.singlenft,
      multiplenft: respData.data.multiplenft,
      usercnt: respData.data.usercnt,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
      saleCnt: 0,
      singlenft: 0,
      multiplenft: 0,
      usercnt: 0
    };
  }
};

export const getMultipleCount = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/routesAdmin/getMultipleTokenCount`,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getSingleCount = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/routesAdmin/getSingleTokenCount`,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getTokenCount = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/routesAdmin/tokenCount`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getUserCount = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/routesAdmin/userCounts`,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const salesCount = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/routesAdmin/salesCount`,
    });

    return {
      status: "success",
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getdashboardchart = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesAdmin/getdashboardchart`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};