import isEmpty from "lib/isEmpty";
// import Web3 from "web3";
import { numberOnly } from "../helper/Helper"
// import config from "../lib/config"

// var web3 = new Web3(config.infuraurl);

export const mintValidations = async (details) => {

    try {
        let errors = {};
        console.log(details.defaultImage,"--",details.selectedImage);
        if (details.defaultImage === false) {
            if (isEmpty(details.selectedImage)||details.selectedImage.exists()) {
                errors.selectedImage = "Image field is required"
            }
        }
        if (isEmpty(details.gamevalue)) {
            errors.gamevalue = "Game field is required"
        }
        if (isEmpty(details.gameListValue)) {
            errors.gameListValue = "Category field is required"
        }
        if (isEmpty(details.itemtypevalue)) {
            errors.itemtypevalue = "ItemType field is required"
        }
        if (isEmpty(details.rarityvalue)) {
            errors.rarityvalue = "Rarity field is required"
        }
        if (isEmpty(details.seasonvalue)) {
            errors.seasonvalue = "Season field is required"
        }
        if (details.propertiesList) {
            for (var i = 0; i < details.propertiesList.length; i++) {

                if (isEmpty(details.propertiesList[i].value)) {
                    errors.propertiesList = "Properties List field is required"
                }
            }
        }
        if (isEmpty(details.tittle)) {
            errors.tittle = "Title field is required"
        }
        if (isEmpty(details.description)) {
            errors.description = "Description field is required"
        }
        if (isEmpty(details.TokenPrice)) {
            errors.TokenPrice = "Token Price field is required"
        }
        else if (isNaN(details.TokenPrice) === true) {
            errors.TokenPrice = '"Token Price" must be a number';
        }
        else if (parseFloat(details.TokenPrice) === 0 || (parseFloat(details.TokenPrice) < 0)) {
            errors.TokenPrice = '"Token Price" must be greater than zero';
        }
        if (isEmpty(details.royalty)) {
            errors.royalty = "Royalty field is required"
        }
        else if (isNaN(details.royalty) === true) {
            errors.royalty = '"Royalty" must be a number';
        }
        else if (parseFloat(details.royalty) === 0 || parseFloat(details.royalty) < 0) {
            errors.royalty = '"Royalty" must be a greater than zero';
        }
        if (details.CollectibleType === "1155") {

            if (isEmpty(details.copies)) {
                errors.copies = "Quantity field is required"
            }
            else if (isNaN(details.copies) === true) {
                errors.copies = '"Quantity" must be a number'
            }
            else if (await numberOnly(details.copies) === false) {
                errors.copies = '"Quantity" must not be a  number'
            }else if (parseFloat(details.copies) === 0 || parseFloat(details.copies)<0) {
                errors.copies = '"Quantity" must not be a greater than zero'
            }
            if (isEmpty(details.maximumpurchase)) {
                errors.maximumpurchase = "Maximum purchase field is required"
            }
            else if (isNaN(details.maximumpurchase) === true) {
                errors.maximumpurchase = '"Maximum purchase" must be a number'
            }
            else if (parseFloat(details.maximumpurchase) > parseFloat(details.copies)) {
                errors.maximumpurchase = "Maximum purchase must  be lesser than copies"
            }
            else if (await numberOnly(details.maximumpurchase) === false) {
                errors.maximumpurchase = '"Maximum purchase" must not be a floating number'
            }else if (parseFloat(details.maximumpurchase) === 0 || parseFloat(details.maximumpurchase)<0) {
                errors.maximumpurchase = '"Maximum purchase" must not be a greater than zero'
            }
        }

        if (!isEmpty(errors)) {
            return errors
        }
    }
    catch (err) {
    }
}