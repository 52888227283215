import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from 'react-redux'

import isLogin from '../../lib/isLogin';

const ConditionRoute = ({ component: Component, layout: Layout, type, store, ...rest }) => {

    // redux-state
    const authData = useSelector(state => state.auth)

    return (
        <Route
            {...rest}
            render={props => {
                if (type === 'auth' && isLogin() === true) {
                    return <Redirect to="/dashboard" />
                } else if (type === 'private' && isLogin() !== true) {
                    return <Redirect to="/login" />
                } else if (type !== 'public' && authData.role !== 'superadmin') {

                    if (authData.restriction && authData.restriction.length > 0) {
                        let restrictionData = authData.restriction.find((item => item.path === props.match.path))
                        if (!restrictionData && authData.restriction[0].path) {
                            return <Redirect to={"/" + authData.restriction[0].path} />
                        }
                    }

                }

                if (Layout) {
                    return (
                        <Layout>
                            <Component {...props} />
                        </Layout>
                    )
                }

                return <Component {...props} />
            }}
        />
    )

};

export default ConditionRoute;