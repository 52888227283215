import React, { useEffect, useState, Fragment } from 'react';
// import material UI
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from '@material-ui/data-grid';
import { Paper } from '@material-ui/core';
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { GetAllNFTAction } from 'actions/admin';
import { ApprovesubmitAction } from 'actions/admin';
import { toastAlert } from 'lib/toastAlert';
import { Link } from "react-router-dom";

import {
    Visibility as VisibilityIcon,
    LibraryBooks as LibraryBook,
    Edit as EditIcon,

} from '@material-ui/icons';
import Tooltip from "@material-ui/core/Tooltip";
// import action

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    flexHeader: {
        display: "flex !important",
        justifyContent: "space-between !important",
        alignItems: "center !important"
    },
    btnPrimary: {
        backgroundColor: "#b462c5",
        color: "#ffffff !important",
        padding: "7px 15px",
        fontSize: "12px",
        fontWeight: "700"
    }
};
const useStyles = makeStyles(styles);


const MintNftLists = (props) => {
    const classes = useStyles();

    // state
    const [data, setData] = useState([])
    const [pageSize, setpageSize] = React.useState(10)
    const columns = ([
        { field: 'tittle', headerName: 'Title', width: 200 },
        { field: 'type', headerName: 'Type', width: 200 },
        {
            field: 'Approval Status',
            headerName: 'Approval Status',
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <>
                        {
                            params && params.row && params.row.ApproveStatus === false ?
                                <Button color="primary" type="submit" onClick={() => handleFormSubmit(params.row._id, 'Approve')}>
                                    Approve
                                </Button>
                                :
                                "Success"
                        }
                    </>
                )
            }

        },
        {
            field: 'MintCallStatus',
            sortable: false,
            disableColumnMenu: true,
            headerName: 'Minting Status',
            width: 200,
            renderCell: (params) => {
                return (
                    <>
                        {
                            params && params.row && params.row.MintCallStatus === false ?


                                <Button color="primary" type="submit" onClick={() => handleFormSubmit(params.row._id, 'Minting')} >
                                    Mint
                                </Button>
                                :
                                "Success"
                        }
                    </>
                )
            }


        },
        {
            field: 'Action',
            headerName: 'Action',
            width: 300,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Fragment>
                        <Tooltip title="view Nftinfo"><Link to={'/viewNft/' + params.row._id}>

                            <VisibilityIcon style={{ color: "#109ebf" }} />
                        </Link></Tooltip>
                        <Tooltip title="view ownerlist"><Link to={'/ownerList/' + params.row.tokenID}>
                            <LibraryBook style={{ color: "#109ebf" }} />
                        </Link></Tooltip>
                        <Tooltip title="Edit nft"><Link to={'/edit-owner/' + params.row._id}>
                            <EditIcon style={{ color: "#109ebf" }} />
                        </Link></Tooltip>
                    </Fragment>
                )
            }
        },
    ])
    const handleFormSubmit = async (id, data) => {
        let datas = {
            id: id,
            mintType: data
        }
        const { status, message } = await ApprovesubmitAction(datas)
        if (status) {
            getAllNftLists()
            toastAlert("success", message, 'mint')
        }
    }
    // function
    const getAllNftLists = async () => {
        const { result } = await GetAllNFTAction()
        if (result) {
            let respData = result.map((item, key) => {
                return {
                    id: key,
                    ...item
                }
            })
            setData(respData)
        }
        else {
        }
    }

    const handlePageSizeChange = async (params) => {
        setpageSize(params.pageSize)
    }
    useEffect(() => {
        getAllNftLists()
    }, [])

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Card>
                    <CardHeader color="primary">
                        <div className={classes.flexHeader}>
                            <h4 className={classes.cardTitleWhite}>NFT Lists</h4>
                            {/* <Link to={'/add'} className={classes.btnPrimary}>Add Admin</Link> */}
                        </div>
                    </CardHeader>
                    <CardBody>
                        {data && data.length > 0 &&
                            <div style={{ height: 600, width: '100%' }}>
                                <DataGrid pageSize={pageSize} pagination rows={data} columns={columns} rowsPerPageOptions={[10, 15]} onPageSizeChange={handlePageSizeChange} />
                            </div>
                        }
                    </CardBody>
                </Card>
            </Paper>
        </div >
    )
}

export default MintNftLists;