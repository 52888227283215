// import package
import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import $ from "jquery";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Checkbox from '@mui/material/Checkbox';
//import avatar from "assets/img/faces/marc.jpg";
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";
import FormLabel from '@mui/material/FormLabel';
// import action
import { getCategoryList } from "./../../actions/admin";
import { nftMint, nftMint_namecheck } from "../../../src/actions/admin";
import { mintValidations } from "validation/mintValidation";


const Styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const initialFormValue = {
  tittle: "",
  description: "",
  TokenPrice: "",
  royalty: "",
  copies: "",
  image: "",
  itemtypevalue: { id: "", value: "" },
  rarityvalue: "",
  seasonvalue: "",
  propertiesList: "",
  gamevalue: { id: "", value: "" },
  maximumpurchase: "",
  useraddress: "",
  privatekey: ""
};

const useStyles = makeStyles(styles, Styles);

const AdminAdd = () => {

  const classes = useStyles();
  const history = useHistory();

  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [multipleImg, setMultipleImg] = useState(false);
  const {
    image,
    tittle,
    description,
    TokenPrice,
    royalty,
    copies,
    itemtypevalue,
    rarityvalue,
    gamevalue,
    seasonvalue,
    propertiesList,
    maximumpurchase,
  } = formValue;


  const [selectedImage, setSelectedImage] = useState([]);
  const [imgTag, setImageTag] = useState();
  const [imgExt, setImageExt] = useState();
  const [defaultImage, setDefaultImage] = useState(false);
  const [gameListValue, setGameListValue] = useState();
  const [CollectibleType, setCollectibleType] = useState("721");
  const [validateError, setValidationError] = useState({});
  const [mintstatus, setMintStatus] = useState(false);
  const [itemtype, setitemtypes] = useState();
  const [rarity, setRarityLists] = useState();
  const [season, setseasons] = useState();
  const [games, setgames] = useState([]);
  const [allCategory, setallCategory] = useState([]);
  const [notforsale, setnotforsale] = useState("yes");
  // function
  const handleChange = (e) => {
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setValidationError("")
    setFormValue(formData);
  };


  const handleChangeCategory = (e, label) => {
    const { value } = e.target;
    if (formValue && formValue.propertiesList &&
      formValue.propertiesList.length > 0) {
      var index = formValue.propertiesList.findIndex(val => val.label === label);
      if (index !== -1) {
        formValue.propertiesList[index].value = value;
      }
      let formData = { ...formValue, ...{ propertiesList: formValue.propertiesList } };
      setFormValue(formData);
      setValidationError("")
    }
  };

  const handleChangeItem = (e) => {
    const { value } = e.target;
    var index = itemtype.findIndex(val => val.category === value);
    var _id = (index !== -1) ? itemtype[index]._id : ""
    let formData = { ...formValue, ...{ itemtypevalue: { value: value, id: _id } } };
    setFormValue(formData);
  }

  const handleChangeRarity = (e) => {
    const { value } = e.target;
    var index = rarity.findIndex(val => val.category === value);
    var _id = (index !== -1) ? rarity[index]._id : ""
    var logo = (index !== -1) ? rarity[index].logo : ""
    let formData = { ...formValue, ...{ rarityvalue: { value: value, id: _id, logo: logo } } };
    setFormValue(formData);
    setValidationError("")
  }

  const handleChangeSeason = (e) => {
    const { value } = e.target;
    var index = season.findIndex(val => val.category === value);
    var _id = (index !== -1) ? season[index]._id : ""
    let formData = { ...formValue, ...{ seasonvalue: { value: value, id: _id } } };
    setFormValue(formData);
    setValidationError("")
  }

  const handleFormSubmit = async (e) => {
    console.log(notforsale, 'notforsale')
    e.preventDefault();
    let datas = {
      defaultImage: defaultImage,
      selectedImage: selectedImage,
      gamevalue: gamevalue,
      gameListValue: gameListValue,
      itemtypevalue: itemtypevalue,
      rarityvalue: rarityvalue,
      seasonvalue: seasonvalue,
      propertiesList: propertiesList,
      tittle: tittle,
      description: description,
      TokenPrice: TokenPrice,
      royalty: royalty,
      CollectibleType: CollectibleType,
      copies: copies,
      maximumpurchase: maximumpurchase,
    }
    console.log(datas, "datas")
    const errorDatas = await mintValidations(datas)
    if (errorDatas) {
      return setValidationError(errorDatas)
    }
    setMintStatus(true)
    let data = {
      image: selectedImage,
      gameList: gameListValue,
      gamevalue: gamevalue,
      itemtypevalue: itemtypevalue,
      rarityvalue: rarityvalue,
      seasonvalue: seasonvalue,
      propertiesList: propertiesList,
      tittle: tittle,
      description: description,
      royalty: royalty,
      TokenPrice: TokenPrice,
      copies: copies,
      CollectibleType: CollectibleType,
      maximumpurchase: maximumpurchase,
      notforsale: notforsale
    };
    //return;

    let { namestatus, namemessage, nameerror } = await nftMint_namecheck(data);
    if (namestatus) {
      toastAlert("error", namemessage, "game add");
      setMintStatus(false)
      return false;
    }

    const { status, message, error } = await nftMint(data);
    if (status) {
      setDefaultImage(false)
      setFormValue(initialFormValue)
      setMintStatus(false)
      toastAlert("success", message, "game add");
      history.push('/mint-NftLists')
    }
    else {
      setMintStatus(false)
      toastAlert("error", error, "game add");
      history.push('/mint-NftLists')

    }
  };
  const multipleSubmit = async (e) => {
    e.preventDefault();
    setMultipleImg(true);
    const type = "1155"
    setCollectibleType(type)

  };
  const singleSubmit = async (e) => {
    e.preventDefault();
    setMultipleImg(false);
    const type = "721"
    setCollectibleType(type)
  };

  const getallNfttypes = async () => {
    const resp = await getCategoryList()
    if (resp && resp.result) {
      setallCategory(resp.result)
      var Itemtypes = resp.result.filter(val => val.categorytype === "Itemtype")
      var Raritys = resp.result.filter(val => val.categorytype === "Rarity")
      var Seasion = resp.result.filter(val => val.categorytype === "Season")
      var Game = resp.result.filter(val => val.categorytype === "Game")

      if (!isEmpty(Itemtypes)) {
        setitemtypes(Itemtypes);
      }
      if (!isEmpty(Raritys)) {
        setRarityLists(Raritys);
      }
      if (!isEmpty(Seasion)) {
        setseasons(Seasion);
      }
      if (!isEmpty(Game)) {
        setgames(Game);
      }
    }
  }
  useEffect(() => {
    getallNfttypes()
  }, []);

  const handleallGameUser = async (e) => {

    setValidationError("")
    setGameListValue(e.target.value);
    let target = e.target.value;

    var index = allCategory.findIndex(val => val.gamesubcategory && val.gamesubcategory.id && val.category === target);

    if (index !== -1) {
      var nameList = allCategory[index].attributes;
      var list = [];
      for (var i = 0; i < nameList.length; i++) {
        list.push({ label: nameList[i].title, value: "", _id: nameList[i].title })
      }
      var index1 = allCategory.findIndex(val => val.gamesubcategory && val.gamesubcategory.id && val.category === target);
      var _id = (index1 !== -1) ? allCategory[index1]._id : ""
      var gamesubid = (index1 !== -1) ? allCategory[index1].gamesubcategory.id : ""
      var category = (index1 !== -1) ? allCategory[index1].gamesubcategory.category : ""

      let formData = { ...formValue, ...{ "propertiesList": list, gamevalue: { value: target, id: _id }, itemtypevalue: { value: category, id: gamesubid } } };
      setFormValue(formData);
    }
  };


  const selectprofileFileChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      var file = event.target.files[0];
      var validExtensions = [
        "jpg",
        "png",
        "gif",
        "webp",
        "mp4"
      ];
      if (file && file.name) {
        var fileName = file.name;
        var fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);
        setImageExt(fileNameExt);
        console.log(fileNameExt, "extension")
        if ($.inArray(fileNameExt, validExtensions) === -1) {
          setDefaultImage(false);
          toastAlert("error", "Only these file types are accepted : " +
            validExtensions.join(", "), "game add");
          return false;
        }
        const fileSize = file.size / 1024 / 1024;
        console.log(fileSize, "filesize")
        if (fileSize > 10) {
          setDefaultImage(false);
          setSelectedImage([]);
          toastAlert("error", "File size exceeds 10 MB", "game add");
          return false;
        }
        setSelectedImage(file);
        setImageTag(URL.createObjectURL(event.target.files[0]));
        setDefaultImage(true);
        setValidationError("")
      } else {
        setDefaultImage(false);
        toastAlert("error", "Only these file types are accepted : " +
          validExtensions.join(", "), "game add");
        return false;
      }
    }
  };

  async function handleCheckbox(event, isChecked, value) {
    var status = (isChecked) ? "yes" : "no"
    setnotforsale(status)
  }
  console.log(gamevalue, 'ddddddddd')
  return (

    <Fragment>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleFormSubmit}
            >
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Mint NFT</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem md={12}>
                    <div className="text-center mb-4">
                      <Button
                        className={(CollectibleType === "721") ? "bg-primary mr-3" : "mr-3"}
                        onClick={singleSubmit}
                      >
                        Single
                      </Button>
                      <Button
                        className={(CollectibleType === "1155") ? "bg-primary" : ""}
                        onClick={multipleSubmit}
                        type="submit"
                      >
                        Multiple
                      </Button>
                    </div>
                  </GridItem>
                </GridContainer>
                <div className="container">
                  <GridContainer className="wow fadeIn form-border">
                    <GridItem md={5}>
                      <h1 className="primary_label">Upload Image</h1>
                      <div className="d-create-file">
                        <p id="file_name">
                          PNG, JPG, GIF, WEBP or MP4. Max 10 MB.
                        </p>
                        <div className="uploadCustomFile">
                          <input
                            type="file"
                            id="photo"
                            required={true}
                            name="image"
                            value={image}
                            className="btn-main"
                            onChange={selectprofileFileChange}
                          />

                        </div>
                        {
                          validateError && validateError.selectedImage && <p className={'error-message'}>{validateError.selectedImage}</p>
                        }
                      </div>
                    </GridItem>

                    <GridItem md={3}>
                      {defaultImage ? (
                        <>
                          <div>
                            <div className="createPreview profileImgPreview">
                              <h1 className="primary_label">Image preview</h1>
                              <div className="nft__item">
                                {imgExt == "mp4" ? <video src={imgTag} name="image" autoPlay playsInLine loop controls alt="profile" height="200px" width="400px" /> : <img name="image" src={imgTag} alt="profile"></img>}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="createPreview profileImgPreview">
                              <h1 className="primary_label">Image preview</h1>
                              <div className="nft__item">
                                <img
                                  src={require("../../assets/img/defaultgame.png")} alt="profile"
                                ></img>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem md={6}>
                      <Box
                        //component="form"
                        sx={{
                          "& .MuiTextField-root": { m: 0, width: "50ch" },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <div>
                          <TextField
                            id={"outlined-select-currency-" + gamevalue._id}
                            select
                            label="Select Game"
                            value={(gamevalue && gamevalue.value) ? gamevalue.value : ""}
                            //name={(gamevalue && gamevalue.id) ? gamevalue.id : ""}
                            onChange={handleallGameUser}
                          >
                            {/* {games &&
                              games.length > 0 &&
                              games.map((option) => (
                                <MenuItem key={option.category} value={option.category}>
                                  {option.category}
                                </MenuItem>
                              ))} */}
                            {games && games.length > 0 ? games.map((option) => (
                              <MenuItem key={option.category} value={option.category}>{option.category}</MenuItem>)) :
                              <MenuItem value={'No Data Available'}>  {'No Data Available'}</MenuItem>}

                          </TextField>
                          {
                            validateError && validateError.gameListValue && <p className={'error-message'}>{validateError.gameListValue}</p>
                          }
                        </div>

                      </Box>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem md={6}>
                      <Box
                        // component="form"
                        sx={{
                          "& .MuiTextField-root": { m: 0, width: "50ch" },
                        }}
                        noValidate
                        autoComplete="off"

                      >
                        <div className="mt-3">


                          {formValue && formValue.propertiesList &&
                            formValue.propertiesList.length > 0 &&
                            formValue.propertiesList.map((option) => (
                              <h6 className="custom_label">
                                {option.label}
                                <TextField

                                  // label="List of properties"
                                  value={option.value}
                                  // value={option.val}
                                  name={option.label}
                                  onChange={(e) => handleChangeCategory(e, option.label)}
                                // onChange={handleChange}                                
                                >
                                </TextField>
                              </h6>
                            ))}
                          {
                            validateError && validateError.propertiesList && <p className={'error-message'}>{validateError.propertiesList}</p>
                          }
                        </div>
                      </Box>
                    </GridItem>

                  </GridContainer>

                  <GridContainer>
                    <GridItem md={6}>
                      <Box
                        //component="form"
                        sx={{
                          "& .MuiTextField-root": { m: 0, width: "50ch" },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <div className="mt-3">
                          <TextField
                            id="outlined-select-currency"
                            select
                            label="Item Type"
                            value={(itemtypevalue && itemtypevalue.value) ? itemtypevalue.value : ""}
                            name="itemtypevalue"
                            onChange={handleChangeItem}
                            disabled={true}
                          // helperText="Please select Category"
                          >
                            {itemtype &&
                              itemtype.length > 0 ?
                              itemtype.map((option) => (
                                <MenuItem key={option.category} id={option.category} value={option.category}>
                                  {option.category}
                                </MenuItem>
                              )) : <MenuItem value={'No Data Available'}>  {'No Data Available'}</MenuItem>}

                          </TextField>
                          {
                            validateError && validateError.itemtypevalue && <p className={'error-message'}>{validateError.itemtypevalue}</p>
                          }
                        </div>
                      </Box>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem md={6}>
                      <Box
                        //component="form"
                        sx={{
                          "& .MuiTextField-root": { m: 0, width: "50ch" },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <div className="mt-3">
                          <TextField
                            id="outlined-select-currency"
                            select
                            label="Select Rarity"
                            value={(rarityvalue && rarityvalue.value) ? rarityvalue.value : ""}
                            name="rarityvalue"
                            onChange={handleChangeRarity}

                          // helperText="Please select Category"
                          >
                            {rarity &&
                              rarity.length > 0 ?
                              rarity.map((option) => (
                                <MenuItem key={option.category} value={option.category}>
                                  {option.category}
                                </MenuItem>
                              )) : <MenuItem value={'No Data Available'}>  {'No Data Available'}</MenuItem>}

                          </TextField>
                          {
                            validateError && validateError.rarityvalue && <p className={'error-message'}>{validateError.rarityvalue}</p>
                          }
                        </div>
                      </Box>
                    </GridItem>
                    <GridItem md={6}>
                      <Box
                        //component="form"
                        sx={{
                          "& .MuiTextField-root": { m: 0, width: "50ch" },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <div className="mt-3">
                          <TextField
                            id="outlined-select-currency"
                            select
                            label="Select Season"
                            value={(seasonvalue && seasonvalue.value) ? seasonvalue.value : ""}
                            name="seasonvalue"
                            onChange={handleChangeSeason}
                          // helperText="Please select Category"
                          >
                            {season &&
                              season.length > 0 ?
                              season.map((option) => (
                                <MenuItem key={option.category} value={option.category}>
                                  {option.category}
                                </MenuItem>
                              )) : <MenuItem value={'No Data Available'}>  {'No Data Available'}</MenuItem>}

                          </TextField>
                          {
                            validateError && validateError.seasonvalue && <p className={'error-message'}>{validateError.seasonvalue}</p>
                          }
                        </div>
                      </Box>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Tittle"
                        name="tittle"
                        value={tittle}
                        onChange={handleChange}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                      {
                        validateError && validateError.tittle && <p className={'error-message'}>{validateError.tittle}</p>
                      }
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Description"
                        name="description"
                        value={description}
                        onChange={handleChange}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                      {
                        validateError && validateError.description && <p className={'error-message'}>{validateError.description}</p>
                      }
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="TokenPrice (MATIC)"
                        name="TokenPrice"
                        value={TokenPrice}
                        onChange={handleChange}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                      {
                        validateError && validateError.TokenPrice && <p className={'error-message'}>{validateError.TokenPrice}</p>
                      }
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Royalty (%)"
                        name="royalty"
                        value={royalty}
                        onChange={handleChange}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                      {
                        validateError && validateError.royalty && <p className={'error-message'}>{validateError.royalty}</p>
                      }
                    </GridItem>
                  </GridContainer>
                  {/* <GridItem xs={12} sm={12} md={5}>
                        <CustomInput
                          labelText="Properties (Optional)"
                          name="properties"
                          value={properties}
                          onChange={handleChange}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                        {
                          validateError && validateError.properties && <p className={'error-message'}>{validateError.properties}</p>
                        }
                      </GridItem> */}
                  {multipleImg && (
                    <>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <CustomInput
                            labelText="Number of copies"
                            name="copies"
                            value={copies}
                            onChange={handleChange}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                          {
                            validateError && validateError.copies && <p className={'error-message'}>{validateError.copies}</p>
                          }
                        </GridItem>
                      </GridContainer>

                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <CustomInput
                            labelText="Maximum purchase"
                            name="maximumpurchase"
                            value={maximumpurchase}
                            onChange={handleChange}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                          {
                            validateError && validateError.maximumpurchase && <p className={'error-message'}>{validateError.maximumpurchase}</p>
                          }
                        </GridItem>
                      </GridContainer>
                    </>
                  )}

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <Checkbox
                        title="Show in marketplace"
                        onChange={handleCheckbox}
                        defaultChecked={true}
                      />
                      <FormLabel>Show in marketplace</FormLabel>
                    </GridItem>
                  </GridContainer>

                </div>

              </CardBody>
              <CardFooter>
                <GridContainer>
                  <GridItem md={12}>
                    {
                      !mintstatus ? (
                        <div className="pl-3">
                          <Button color="primary" type="submit">
                            Mint
                          </Button>
                        </div>

                      ) : (
                        <div className="pl-3">
                          <Button color="primary" type="submit" disabled>
                            processing
                          </Button>
                        </div>
                      )
                    }
                  </GridItem>
                </GridContainer>

              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </Fragment>
  );
};

export default AdminAdd;
