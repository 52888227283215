// import package
import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import $ from "jquery"
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
// import lib
import { toastAlert } from "../../../lib/toastAlert";
import isEmpty from "lib/isEmpty";
import { EditCategory } from "actions/admin";
import { UpdateCategoryAction } from "actions/admin";
import config from "../../../lib/config"

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
    },
};

const initialFormValue = {
    name: "",
    image: ""
};

const useStyles = makeStyles(styles);

const RarityEdit = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams();
    const rarityid = id;
    // state
    const [formValue, setFormValue] = useState(initialFormValue);
    const { name, image } = formValue;
    const [defaultImage, setDefaultImage] = useState(false);
    const [Logo, setLogo] = useState();
    const [selectedImage, setSelectedImage] = useState(null);
    const [ValidateError, setValidationError] = useState({})

    useEffect(() => {
        getRaritylist()
        //eslint-disable-next-line
    }, [])

    // function
    const handleChange = (e) => {
        const { name, value } = e.target;
        let formData = { ...formValue, ...{ [name]: value } };
        setFormValue(formData);
        setValidationError("")
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        let errors = {};
        if (isEmpty(name)) {
            errors.name = "Name field is required"
        }
        if (!defaultImage && (isEmpty(Logo))) {
            if (isEmpty(selectedImage)) {
                errors.selectedImage = "Image field is required"
            }
        }
        if (!isEmpty(errors)) {
            return setValidationError(errors)
        }
        let formData = new FormData();
        formData.append("image", selectedImage);
        formData.append("category", name);
        formData.append("id", rarityid);

        const { status, error } = await UpdateCategoryAction(formData);
        if (status) {
            toastAlert("success", "Updated successfully", "game update");
            history.push('/raritylist')
        }
        else {
            toastAlert("error", error, "game add");
        }
    };

    const getRaritylist = async () => {
        const data = {
            id: rarityid
        }
        const { result } = await EditCategory(data)
        if (result) {
            var logoPrvw = config.baseUrl + "/category/" + result.logo;
            setLogo(logoPrvw);
            let formData = { ...formValue, ...{ name: result.category } }
            setFormValue(formData)
        }

    }

    const selectprofileFileChange = async (event) => {
        if (event.target.files && event.target.files[0]) {
            var file = event.target.files[0];
            var validExtensions = [
                "jpg",
                "png"
            ];
            if (file && file.name) {
                var fileName = file.name;
                var fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);
                if ($.inArray(fileNameExt, validExtensions) === -1) {
                    setDefaultImage(false);
                    // setLogo("")
                    toastAlert("error", "Only these file types are accepted : " +
                        validExtensions.join(", "), "game add");
                    getRaritylist()
                    return false;
                } else {
                    setSelectedImage(file);
                    setLogo(URL.createObjectURL(event.target.files[0]));
                    setDefaultImage(true);
                    setValidationError("")
                }
            } else {
                setDefaultImage(false);
                setLogo("")
                toastAlert("error", "Only these file types are accepted : " +
                    validExtensions.join(", "), "game add");
            }
        }
    };

    return (
        <div>
            <div className="page_header">
                <Link className="btn btn_custom mr-3 mb-4" to="/raritylist">Back</Link>
            </div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <form
                            className={classes.form}
                            noValidate
                            onSubmit={handleFormSubmit}
                        >
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Edit Rarity</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <div className="container">
                                        <div className="row wow fadeIn form-border">
                                            <GridItem xs={12} sm={12} md={12}>
                                                <CustomInput
                                                    labelText="Rarity"
                                                    name="name"
                                                    value={name}
                                                    onChange={handleChange}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                />
                                                {
                                                    ValidateError && ValidateError.name && <p className={'error-message'}>{ValidateError.name}</p>
                                                }
                                            </GridItem>
                                            <label className="primary_label"> </label>

                                        </div>

                                        <div className="col-lg-5">
                                            <div className="d-create-file">

                                                <div className="uploadCustomFile">
                                                    <input
                                                        type="file"
                                                        id="photo"
                                                        required={true}
                                                        name="image"
                                                        value={image}
                                                        className="btn-main"
                                                        onChange={selectprofileFileChange}
                                                    />
                                                    {
                                                        ValidateError && ValidateError.selectedImage && <p className={'error-message'}>{ValidateError.selectedImage}</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        {Logo && Logo !== "" &&
                                            <>
                                                <div className="col-lg-3">
                                                    <div className="createPreview profileImgPreview">
                                                        <h1 className="primary_label">Image preview</h1>
                                                        <div className="nft__item">
                                                            <img src={Logo} alt="rarity"></img>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }

                                    </div>
                                </GridContainer>
                            </CardBody>
                            <CardFooter>
                                <div className="pl-3">
                                    <Button color="primary" type="submit">
                                        Update
                                    </Button>
                                </div>
                            </CardFooter>
                        </form>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
};

export default RarityEdit;
