// import package
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import { getNFT } from "../../../src/actions/admin";
import config from "../../lib/config";


const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};


const useStyles = makeStyles(styles);

const ViewNft = (props) => {
  const classes = useStyles();
  const { id } = useParams();
  const [infoDetails, setinfoDetails] = useState({});

  useEffect(() => {
    getInfo()
    //eslint-disable-next-line
  }, [])

  async function getInfo() {
    var { result } = await getNFT({ id: id })
    if (result && result._id) {
      console.log(result, "result")
      result.ext = (result && result.image && result.image != "") ? result.image.split('.') : "png";
      result.ext = result.ext[1];
      setinfoDetails(result);
    }
  }

  return (
    <div>
      <div className="page_header">
        <Link className="btn btn_custom mr-3 mb-4" to="/mint-NftLists">Back</Link>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form
              className={classes.form}
              noValidate
            >
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>NFT Info</h4>
              </CardHeader>
              <CardBody>

                <div className="container">
                  <GridContainer className="wow fadeIn form-border">
                    <GridItem md={3}>
                      {infoDetails && infoDetails.Ipfsimage ? (
                        <>
                          <div>
                            <div className="createPreview profileImgPreview">
                              <div className="nft__item">
                                {infoDetails.ext == "mp4" ? <video src={config.IPFS_IMG + "/" + infoDetails.Ipfsimage} name="image" autoPlay playsInLine loop controls alt="profile" height="200px" width="400px" /> : <img src={config.IPFS_IMG + "/" + infoDetails.Ipfsimage} alt="nftlogo"></img>}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <div className="createPreview profileImgPreview">
                              <h1 className="primary_label">Image preview</h1>
                              <div className="nft__item">
                                <img
                                  src={require("../../assets/img/defaultgame.png")} alt="nft"
                                ></img>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem md={6}>
                      <Box
                        //component="form"
                        sx={{
                          "& .MuiTextField-root": { m: 0, width: "50ch" },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <div>
                          <CustomInput
                            labelText="Game"
                            name="Game"
                            value={(infoDetails && infoDetails.Game && infoDetails.Game.value) ? infoDetails.Game.value : ""}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </div>

                      </Box>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem md={6}>
                      <Box
                        //component="form"
                        sx={{
                          "& .MuiTextField-root": { m: 0, width: "50ch" },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <div className="mt-3">
                          <label>Attributes</label>
                          {infoDetails && infoDetails.propertiesList &&
                            infoDetails.propertiesList.length > 0 &&
                            infoDetails.propertiesList.map((option, i) => (
                              <div key={i}>
                                <h6 className="custom_label">
                                  {option.label}
                                  <TextField
                                    value={option.value}
                                    name={option.label}
                                  >
                                  </TextField>
                                </h6>
                              </div>
                            ))}

                        </div>
                      </Box>
                    </GridItem>

                  </GridContainer>

                  <GridContainer>
                    <GridItem md={6}>
                      <Box
                        //component="form"
                        sx={{
                          "& .MuiTextField-root": { m: 0, width: "50ch" },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <div className="mt-3">
                          <div>
                            <CustomInput
                              labelText="Item Type"
                              name="ItemType"
                              value={(infoDetails && infoDetails.ItemType && infoDetails.ItemType.value) ? infoDetails.ItemType.value : ""}
                              formControlProps={{
                                fullWidth: true,
                              }}
                            />
                          </div>
                        </div>
                      </Box>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem md={6}>
                      <Box
                        // component="form"
                        sx={{
                          "& .MuiTextField-root": { m: 0, width: "50ch" },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <div className="mt-3">
                          <div>
                            <CustomInput
                              labelText="Rarity"
                              name="Rarity"
                              value={(infoDetails && infoDetails.Rarity && infoDetails.Rarity.value) ? infoDetails.Rarity.value : ""}
                              formControlProps={{
                                fullWidth: true,
                              }}
                            />
                          </div>
                        </div>
                      </Box>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem md={6}>
                      <Box
                        //component="form"
                        sx={{
                          "& .MuiTextField-root": { m: 0, width: "50ch" },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <div className="mt-3">
                          <div>
                            <CustomInput
                              labelText="Season"
                              name="Season"
                              value={(infoDetails && infoDetails.Season && infoDetails.Season.value) ? infoDetails.Season.value : ""}
                              formControlProps={{
                                fullWidth: true,
                              }}
                            />
                          </div>
                        </div>
                      </Box>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Tittle"
                        name="tittle"
                        value={(infoDetails && infoDetails.tittle) ? infoDetails.tittle : ""}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />

                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Description"
                        name="description"
                        value={(infoDetails && infoDetails.description) ? infoDetails.description : ""}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />

                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Royalty (%)"
                        name="royalty"
                        value={(infoDetails && infoDetails.royalty) ? infoDetails.royalty : ""}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />

                    </GridItem>
                  </GridContainer>

                  {infoDetails && infoDetails.type === 1155 && (
                    <>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <CustomInput
                            labelText="Number of copies"
                            name="copies"
                            value={(infoDetails && infoDetails.totalQuantity) ? infoDetails.totalQuantity : ""}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                      </GridContainer>

                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <CustomInput
                            labelText="Maximum purchase"
                            name="maximumpurchase"
                            value={(infoDetails && infoDetails.maximumpurchase) ? infoDetails.maximumpurchase : ""}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />

                        </GridItem>
                      </GridContainer>
                    </>
                  )}

                </div>

              </CardBody>

            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default ViewNft;
