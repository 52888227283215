import React, { useEffect, useState } from 'react';
// import material UI
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from '@material-ui/data-grid';
import { Paper } from '@material-ui/core';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {Getuserlist} from "actions/admin"
import moment from 'moment';

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    flexHeader: {
        display: "flex !important",
        justifyContent: "space-between !important",
        alignItems: "center !important"
    },
    btnPrimary: {
        backgroundColor: "#b462c5",
        color: "#ffffff !important",
        padding: "7px 15px",
        fontSize: "12px",
        fontWeight: "700"
    }
};
const useStyles = makeStyles(styles);


const Userlist = (props) => {
    const classes = useStyles();

    // state
    const [data, setData] = useState([])
    const [pageSize, setpageSize] = React.useState(10)
    const columns = ([
        { field: 'date', headerName: 'Date', width: 300 ,filterable: false,
    renderCell: (params) => {
        return (moment((params.row.date).toString()).format('MMM,Do YYYY, hh:mm A'))
        
    }},
        { field: 'address', headerName: 'Address', width: 400 }
    ])
  
    // function
    const getuserlist = async () => {
        const { result } = await Getuserlist()
        if (result) {
         
            let respData = result.map((item, key) => {
                return {
                    id: key,
                    ...item
                }
            })
            setData(respData)
        }
        else {
        }
    }
    const handlePageSizeChange =async(params)=>{
        setpageSize(params.pageSize)
    }

    useEffect(() => {
        getuserlist()
    }, [])

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Card>
                    <CardHeader color="primary">
                        <div className={classes.flexHeader}>
                            <h4 className={classes.cardTitleWhite}>User List</h4>
                        </div>
                    </CardHeader>
                    <CardBody>
                        {data && data.length > 0 &&
                            <div style={{ height: 600, width: '100%' }}>
                                <DataGrid pageSize={pageSize} pagination rows={data} columns={columns} rowsPerPageOptions={[10,15]} onPageSizeChange={handlePageSizeChange} />
                            </div>
                        }
                    </CardBody>
                </Card>
            </Paper>
        </div >
    )
}

export default Userlist;