// import package
import React, { useState, useEffect, Fragment } from "react";
import { useHistory, Link } from "react-router-dom";
import $ from "jquery"
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
//action
import { AddCategoryAction } from "../../../actions/admin";
import { getCategoryList } from 'actions/admin';
//lib
import isEmpty from "lib/isEmpty";
import { toastAlert } from "../../../lib/toastAlert";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const initialFormValue = {
  name: "",
  image: ""
};

const useStyles = makeStyles(styles);

const AdminAdd = (props) => {
  const classes = useStyles();
  const history = useHistory();

  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const { name, image } = formValue;
  const [values, setValues] = useState({ val: [{ title: "", error: "" }] });
  const [selectedImage, setSelectedImage] = useState(null);
  const [Logo, setLogo] = useState();
  const [defaultImage, setDefaultImage] = useState(false);
  const [itemTypes, setitemTypes] = useState({ id: "", category: "" });
  const [itemList, setitemList] = useState([]);
  const [ValidateError, setValidationError] = useState({})

  const fetchCategory = async () => {
    try {
      const { status, result } = await getCategoryList({ categorytype: "Itemtype" });
      if (status) {
        let respData = result.map((item, key) => {
          return {
            id: key,
            ...item
          }
        })
        setitemList(respData)
      } else { }
    } catch (err) { }
  }

  useEffect(() => {
    fetchCategory()
  }, [])

  // function
  const handleChange = (e) => {
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    setValidationError("")
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    let errors = {};

    if (isEmpty(name)) {
      errors.name = "Name field is required"
    }
    if (!defaultImage) {
      if (isEmpty(selectedImage)) {
        errors.selectedImage = "Image field is required"
      }
    }
    if (isEmpty(itemTypes)) {
      errors.itemTypes = "ItemType field is required"
    }
    if (values && values.val) {
      for (var i = 0; i < values.val.length; i++) {
        if (isEmpty(values.val[i].title)) {
          errors.values = "Properties field is required"
        }
      }
    }
    if (!isEmpty(errors)) {
      return setValidationError(errors)
    }
    let formData = new FormData();
    formData.append("imagefrom", "addgameimage")
    formData.append("image", selectedImage);
    formData.append("category", name);
    formData.append("attributes", JSON.stringify(values.val));
    formData.append("categorytype", "Game");
    formData.append("itemTypes", JSON.stringify(itemTypes));

    const { status, error } = await AddCategoryAction(formData);
    if (status) {
      toastAlert("success", "Added successfully", "game add");
      history.push('/game-list')
    } else if (error === "Game is already exits") {
      toastAlert("error", "Game name already exits", "game add");
    }
  };

  function removeClick(i) {
    let vals = [...values.val];
    vals.splice(i, 1);
    setValues({ val: vals });
  }
  function handleChanges(event) {
    let errors = {};
    let checkVal = values && values.val.length > 0 && values.val.map(item => item.title)
    if (checkVal.includes(event.target.value)) {
      errors.values = "Properties should not be same"
      return setValidationError(errors)
    } else {
      let vals = [...values.val];
      vals[this].title = event.target.value;
      setValues({ val: vals });
    }
    setValidationError("")
  }

  const addClick = async (e) => {
    e.preventDefault();
    for (var i = 0; i < values.val.length; i++) {
      if (values.val[i] && !values.val[i].title) {
        toastAlert("error", "Please enter properties", "testsss");
        return;
      }
    }
    setValues({ val: [...values.val, { title: "" }] });
  };

  function createInputs() {

    var errorLength = values.val.length - 1;
    return values.val.map((el, i) => (

      <Fragment key={i}>
        <div className="col-md-3">
          <input
            type="text"
            name="item_royalties"
            id={i}
            className="form-control mb-3"
            placeholder="e.g. Name"
            onChange={handleChanges.bind(i)}
            value={el.title}
            autoComplete="off"
          />
          {
            errorLength === i && ValidateError && ValidateError.values && <p className={'error-message'}>{ValidateError.values}</p>
          }
        </div>

        {i !== 0 && (
          <div>
            <i onClick={() => removeClick(i)} className="fa fa-times deleteicon"></i>
          </div>
        )}
      </Fragment>
    ));
  }

  const selectprofileFileChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      var file = event.target.files[0];
      var validExtensions = [
        "jpg",
        "png"
      ];
      if (file && file.name) {
        var fileName = file.name;
        var fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);
        if ($.inArray(fileNameExt, validExtensions) === -1) {
          setDefaultImage(false);
          setLogo("")
          toastAlert("error", "Only these file types are accepted : " +
            validExtensions.join(", "), "game add");
          return false;
        } else {
          setSelectedImage(file);
          setLogo(URL.createObjectURL(event.target.files[0]));
          setDefaultImage(true);
          setValidationError("")
        }
      } else {
        setDefaultImage(false);
        setLogo("")
        toastAlert("error", "Only these file types are accepted : " +
          validExtensions.join(", "), "game add");
      }
    }
  };

  const handleChangeItem = (e) => {
    const { value } = e.target;
    var index = itemList.findIndex(val => val.category === value);
    if (index !== -1) {
      setitemTypes({ id: itemList[index]._id, category: itemList[index].category })
      setValidationError("")
    }
  }

  return (
    <div>
      <div className="page_header">
        <Link className="btn btn_custom mr-3 mb-4" to={'/game-list'}>Back</Link>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleFormSubmit}
            >
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Add Game</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <div className="container">
                    <div className="wow fadeIn form-border">
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Name"
                          name="name"
                          value={name}
                          onChange={handleChange}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                        {
                          ValidateError && ValidateError.name && <p className={'error-message'}>{ValidateError.name}</p>
                        }
                      </GridItem>
                    </div>
                    <div className="row">
                      <div className="col-lg-5">
                        <h1 className="primary_label">Upload Image</h1>
                        <div className="d-create-file">
                          <div className="uploadCustomFile">
                            <input
                              type="file"
                              id="photo"
                              required={true}
                              name="image"
                              value={image}
                              className="btn-main"
                              onChange={selectprofileFileChange}
                            />
                            {
                              ValidateError && ValidateError.selectedImage && <p className={'error-message'}>{ValidateError.selectedImage}</p>
                            }
                          </div>
                        </div>
                      </div>

                      {Logo && Logo !== "" &&
                        <>
                          <div className="col-lg-3">
                            <div className="createPreview profileImgPreview">
                              <h1 className="primary_label">Image preview</h1>
                              <div className="nft__item">
                                <img src={Logo} alt="gamelogo"></img>
                              </div>
                            </div>
                          </div>
                        </>
                      }
                      <label className="primary_label"> </label>
                    </div>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          select
                          className="add_game_text"
                          id="outlined-select-currency"

                          label="Select Item Type"
                          value={itemTypes.category}
                          name="itemtypevalue"
                          onChange={handleChangeItem}
                        // helperText="Please select Category"
                        >
                          {itemList &&
                            itemList.length > 0 ?
                            itemList.map((option) => (
                              <MenuItem key={option.category} id={option.category} value={option.category}>
                                {option.category}
                              </MenuItem>
                            )) : <MenuItem> </MenuItem>}

                        </TextField>
                        {
                          ValidateError && ValidateError.itemTypes && <p className={'error-message'}>{ValidateError.itemTypes}</p>
                        }
                      </GridItem>
                    </GridContainer>

                    <div className="row">
                      {createInputs()}
                    </div>
                    <button
                      type="button"
                      onClick={addClick}
                      className="btn btn-outline-danger"
                    >
                      {" "}
                      Add Properties
                    </button>

                  </div>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <div>
                  <Button color="primary" type="submit">
                    Add
                  </Button>
                </div>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default AdminAdd;
