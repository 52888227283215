// import package
import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import isEmpty from "lib/isEmpty";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
// import action
import { AddCategoryAction } from "actions/admin";
// import lib
import { toastAlert } from "../../../lib/toastAlert";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const initialFormValue = {
  name: "",
};

const useStyles = makeStyles(styles);

const AddItemType = (props) => {
  const classes = useStyles();
  const history = useHistory();

  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const { name } = formValue;
  const [ValidateError, setValidationError] = useState({})
  // function
  const handleChange = (e) => {
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    setValidationError("")
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    let errors = {};
    if (isEmpty(name)) {
      errors.name = "Name field is required"
    }
    if (!isEmpty(errors)) {
      return setValidationError(errors)
    }
    let data = {
      category: name,
      categorytype: "Itemtype"
    };
    const { status, error } = await AddCategoryAction(data);
    if (status) {
      setFormValue(initialFormValue)
      toastAlert("success", "Added successfully", "itemadd");
      history.push('/itemTypeList')
    }
    else {
      toastAlert('error', error, "itemadd")
    }
  };


  return (
    <div>
      <div className="page_header">
        <Link className="btn btn_custom mr-3 mb-4" to={'/itemTypeList'}>Back</Link>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleFormSubmit}
            >
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Add item type</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <div className="container">
                    <div className="row wow fadeIn form-border">
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Item Type"
                          name="name"
                          value={name}
                          onChange={handleChange}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                        {
                          ValidateError && ValidateError.name && <p className={'error-message'}>{ValidateError.name}</p>
                        }
                      </GridItem>
                      <label className="primary_label"> </label>
                      {/* {createInputs()}
                      <button
                        type="button"
                        onClick={addClick}
                        class="btn btn-outline-danger"
                      >
                        {" "}
                        Add Type
                      </button> */}
                    </div>
                  </div>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">
                  Add
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default AddItemType;
