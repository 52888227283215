// import axios
import axios from "axios";

// import lib
import config from "../lib/config";
import { getAuthToken } from "../lib/localStorage";

axios.defaults.headers.common["Authorization"] = getAuthToken();


export const AddCategoryAction = async (data) => {
  try {
    let respData = await axios({
      method: "POST",
      url: `${config.baseUrl}/v2/routesAdmin/add-category`,
      data,
    });
    return {
      status: (respData && respData.data && respData.data.status) ? respData.data.status : false,
      result: respData,
      message: (respData && respData.data && respData.data.message) ? respData.data.message : ""
    };
  } catch (e) {
    return {
      error: e.response.data.message,
    };
  }
};


export const UpdateCategoryAction = async (data) => {
  try {
    let respData = await axios({
      method: "POST",
      url: `${config.baseUrl}/v2/routesAdmin/update-category`,
      data,
    });
    return {
      status: true,
      result: respData,
    };
  } catch (e) {
    return {
      error: e.response.data.message,
    };
  }
};

export const AddItemTypeAction = async (data) => {
  try {
    let respData = await axios({
      method: "POST",
      url: `${config.baseUrl}/v2/routesAdmin/addItemType`,
      data,
    });
    return {
      status: true,
      result: respData,
    };
  } catch (e) {
    return {
      error: e.response.data.message,
    };
  }
};

export const AddRarityAction = async (formData) => {

  try {
    let respData = await axios({
      method: "POST",
      url: `${config.baseUrl}/v2/routesAdmin/add-category`,
      data: formData,
    });
    return {
      status: true,
      result: respData,
    };
  } catch (e) {
    return {
      error: e.response.data.message,
    };
  }
};

export const AddSeasonAction = async (data) => {
  try {
    let respData = await axios({
      method: "POST",
      url: `${config.baseUrl}/v2/routesAdmin/add-category`,
      data,
    });
    return {
      status: true,
      result: respData,
    };
  } catch (e) {
    return {
      error: e.response.data.message,
    };
  }
};

export const ApprovesubmitAction = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/approveOrmint-nft`,
      data,
    });
    return {
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      error: err.response.data.error,
    };
  }
};

export const EditAdmin = async (id) => {
  try {
    let respData = await axios({
      method: "POST",
      url: `${config.baseUrl}/v2/routesAdmin/editAdmin/` + id,
    });
    return {
      status: true,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: false,
      err: err.response,
    };
  }
};

export const EditItemTypeAction = async (data) => {
  try {
    let respData = await axios({
      method: "POST",
      url: `${config.baseUrl}/v2/routesAdmin/editItemtype`,
      data,
    });
    return {
      status: true,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (e) {
    return {
      error: e.response.data.message,
    };
  }
};

export const EditCategory = async (data) => {
  try {
    let respData = await axios({
      method: "POST",
      url: `${config.baseUrl}/v2/routesAdmin/edit-category`,
      data,
    });
    return {
      status: true,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (e) {
    return {
      error: e.response.data.message,
    };
  }
};

export const EditRarityAction = async (data) => {
  try {
    let respData = await axios({
      method: "POST",
      url: `${config.baseUrl}/v2/routesAdmin/edit-rarity`,
      data,
    });
    return {
      status: true,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (e) {
    return {
      error: e.response.data.message,
    };
  }
};

export const EditSeasonAction = async (data) => {
  try {
    let respData = await axios({
      method: "POST",
      url: `${config.baseUrl}/v2/routesAdmin/edit-season`,
      data,
    });
    return {
      status: true,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (e) {
    return {
      error: e.response.data.message,
    };
  }
};

export const GetAllNFTAction = async () => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/get-allnftlists`,
    });

    return {
      status: true,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      error: err.response.data.error,
    };
  }
};

export const UpdateAdmin = async (data) => {
  try {
    let respData = await axios({
      method: "POST",
      url: `${config.baseUrl}/v2/routesAdmin/updateAdmin/`,
      data,
    });
    return {
      status: true,
      result: respData,
    };
  } catch (err) {
    return {
      status: false,
      error: err.response,
    };
  }
};

export const addAdmin = async (data) => {
  try {
    let respData = await axios({
      method: "POST",
      url: `${config.baseUrl}/v2/routesAdmin/addAdmin`,
      data,
    });
    return {
      status: true,
      result: respData,
    };
  } catch (err) {

    return {
      status: false,
      err: err.response,
    };
  }
};

export const addGames = async (data) => {
  try {
    let respData = await axios({
      method: "POST",
      url: `${config.baseUrl}/v2/routesAdmin/addGames`,
      data,
    });
    return {
      status: true,
      result: respData,
    };
  } catch (e) {

  }
};

export const adminManagement = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/routesAdmin/adminManagement`,
    });
    return {
      status: true,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: false,
      error: err.response,
    };
  }
};

export const allGamesUser = async () => {
  try {
    let respData = await axios({
      method: "GET",
      url: `${config.baseUrl}/v2/routesAdmin/allGamesUser`,
    });
    return {
      status: true,
      result: respData.data.data,
    };
  } catch (e) {

  }
};

export const deleteAdmin = async (id) => {

  try {
    let respData = await axios({
      method: "POST",
      url: `${config.baseUrl}/api/routesAdmin/deleteAdmin/` + id.id,
    });
    return {
      status: true,
      result: respData.data.data,
    };
  } catch (err) {

    return {
      status: false,
      err: err.response,
    };
  }
};

export const deleteItemType = async (data) => {
  try {
    let respData = await axios({
      method: "POST",
      url: `${config.baseUrl}/v2/routesAdmin/deleteItemtype`,
      data,
    });
    return {
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (e) {
    return {
      error: e.response.data.message,
    };
  }
};

export const deleteCategory = async (data) => {
  try {
    let respData = await axios({
      method: "POST",
      url: `${config.baseUrl}/v2/routesAdmin/delete-category`,
      data,
    });
    return {
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (e) {
    return {
      error: e.response.data.message,
    };
  }
};


export const deleteRarity = async (data) => {
  try {
    let respData = await axios({
      method: "POST",
      url: `${config.baseUrl}/v2/routesAdmin/deleteRarity`,
      data,
    });
    return {
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (e) {
    return {
      error: e.response.data.message,
    };
  }
};

export const deleteSeason = async (data) => {
  try {
    let respData = await axios({
      method: "POST",
      url: `${config.baseUrl}/v2/routesAdmin/deleteSeason`,
      data,
    });
    return {
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.data,
    };
  } catch (e) {
    return {
      error: e.response.data.message,
    };
  }
};

export const fetchAllRarityLists = async () => {
  try {
    let respData = await axios({
      method: "GET",
      url: `${config.baseUrl}/v2/routesAdmin/getAllRarity`,
    });
    return {
      status: true,
      result: respData.data.data,
    };
  } catch (e) {
    return {
      error: e.response.data.message,
    };
  }
};

export const fetchAllSeasonLists = async () => {
  try {
    let respData = await axios({
      method: "GET",
      url: `${config.baseUrl}/v2/routesAdmin/getAllseason`,
    });
    return {
      status: true,
      result: respData.data.data,
    };
  } catch (e) {
    return {
      error: e.response.data.message,
    };
  }
};

export const getAllAdmin = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.API_URL}/adminapi/admin`,
    });
    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getCategoryList = async (data) => {
  try {
    let respData = await axios({
      method: "POST",
      url: `${config.baseUrl}/v2/routesAdmin/category-list`,
      data
    });
    return {
      status: true,
      result: respData.data.data,
    };
  } catch (e) {
    return {
      error: e.response.data.message,
    };
  }
};

export const getUniqueItemType = async (data) => {
  try {
    let respData = await axios({
      method: "POST",
      url: `${config.baseUrl}/v2/routesAdmin/getuniqueitemType`,
      data,
    });
    return {
      status: true,
      result: respData.data.data,
    };
  } catch (e) {
    return {
      error: e.response.data.message,
    };
  }
};

export const getUniqueRarity = async (data) => {
  try {
    let respData = await axios({
      method: "POST",
      url: `${config.baseUrl}/v2/routesAdmin/getunique-rarity`,
      data,
    });
    return {
      status: true,
      result: respData.data.data,
    };
  } catch (e) {
    return {
      error: e.response.data.message,
    };
  }
};

export const getUniqueSeason = async (data) => {
  try {
    let respData = await axios({
      method: "POST",
      url: `${config.baseUrl}/v2/routesAdmin/getunique-season`,
      data,
    });
    return {
      status: true,
      result: respData.data.data,
    };
  } catch (e) {
    return {
      error: e.response.data.message,
    };
  }
};

export const getallnfttypesAction = async () => {
  try {
    let respData = await axios({
      method: "GET",
      url: `${config.baseUrl}/v2/routesAdmin/getall-nfttypes`,
    });
    return {
      status: true,
      result: respData.data.data,
    };
  } catch (e) {

  }
};
export const nftMint_namecheck = async (data) => {
  let namedata = {"tittle":data.tittle};
    try {
      let respData = await axios({
        method: "post",
        url: `${config.baseUrl}/v2/routesAdmin/nftMint-checkname`,
        data: namedata,
      });
      return {
        namestatus: respData.data.status,
        namemessage: respData.data.message,
        nameresult: respData,
      };
    } catch (err) {
      return {
        nameerror: err.response.data.message,
      };
    }
  }

export const nftMint = async (data) => {
  let formData = new FormData();
  formData.append("image", data.image);
  formData.append("gameList", data.gameList);
  formData.append("propertiesList", JSON.stringify(data.propertiesList));
  formData.append("tittle", data.tittle);
  formData.append("description", data.description);
  formData.append("TokenPrice", data.TokenPrice);
  formData.append("royalty", data.royalty);
  formData.append("CollectibleType", data.CollectibleType);
  formData.append("copies", data.copies);
  formData.append("itemtypevalue", JSON.stringify(data.itemtypevalue));
  formData.append("rarityvalue", JSON.stringify(data.rarityvalue));
  formData.append("seasonvalue", JSON.stringify(data.seasonvalue));
  formData.append("gamevalue", JSON.stringify(data.gamevalue));
  formData.append("maximumpurchase", data.maximumpurchase);
  formData.append("useraddress", data.useraddress);
  formData.append("privatekey", data.privatekey);
  formData.append("notforsale", data.notforsale);

  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/nftMint`,
      data: formData,
    });
    return {
      status: true,
      message: respData.data.message,
      result: respData,
    };
  } catch (err) {
    return {
      error: err.response.data.message,
    };
  }
};

export const roleAdmin = async (id) => {
  try {
    let respData = await axios({
      method: "POST",
      url: `${config.baseUrl}/v2/routesAdmin/roleAdmin/` + id,
    });
    return {
      status: true,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: false,
      err: err.response,
    };
  }
};

export const updateRole = async (data) => {
  try {
    let respData = await axios({
      method: "POST",
      url: `${config.baseUrl}/v2/routesAdmin/updateRole/`,
      data,
    });
    return {
      status: true,
      result: respData,
      message: respData.data.message,
    };
  } catch (err) {
    return {
      status: false,
      error: err.response,
    };
  }
};

export const getNFT = async (data) => {
  try {
    let respData = await axios({
      method: "POST",
      url: `${config.baseUrl}/v2/routesAdmin/get-nft`,
      data: data
    });
    return {
      status: true,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      status: false,
      err: err.response,
    };
  }
};

export const GetNFTOwnerAction = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/get-owner-lists`,
      data: data
    });

    return {
      status: true,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      error: err.response.data.error,
    };
  }
};
export const Getbuylist = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesAdmin/get-buy-lists`,
      data: data
    });

    return {
      status: true,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      error: err.response.data.error,
    };
  }
};
export const Getuserlist = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesAdmin/get-user-lists`,
      data: data
    });

    return {
      status: true,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      error: err.response.data.error,
    };
  }
};
export const Gettodaybuylist = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesAdmin/get-today-buylists`,
      data: data
    });

    return {
      status: true,
      result: respData.data.data,
    };
  } catch (err) {
    return {
      error: err.response.data.error,
    };
  }
};

export const UpdateSettings = async (data) => {
  try {
    let respData = await axios({
      method: "POST",
      url: `${config.baseUrl}/v2/routesAdmin/update-settings`,
      data,
    });
    return {
      status: (respData && respData.data && respData.data.status) ? respData.data.status : false,
      result: respData,
      message: (respData && respData.data && respData.data.message) ? respData.data.message : ""
    };
  } catch (e) {
    return {
      error: e.response.data.message,
    };
  }
};

export const GetSettings = async (data) => {
  try {
    let respData = await axios({
      method: "GET",
      url: `${config.baseUrl}/v2/routesAdmin/get-settings`
    });
    return {
      status: (respData && respData.data && respData.data.status) ? respData.data.status : false,
      result: (respData && respData.data && respData.data.settings) ? respData.data.settings : {},
      message: (respData && respData.data && respData.data.message) ? respData.data.message : ""
    };
  } catch (e) {
    return {
      error: e.response.data.message,
    };
  }
};

export const editNFT = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/edit-nft`,
      data
    });
    return {
      status: true,
      result: respData.data.data,
      ownerList: (respData.data && respData.data.ownerList) ? respData.data.ownerList : []
    };
  } catch (e) {

  }
};

export const updateNFT = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/update-nft`,
      data
    });
    return {
      status: true,
      message: respData.data.message
    };
  } catch (e) {

  }
};

