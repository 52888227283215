// import package
import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
// import lib
import { toastAlert } from "../../../lib/toastAlert";

import { EditCategory, UpdateCategoryAction } from "actions/admin";
import isEmpty from "lib/isEmpty";

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
    },
};

const initialFormValue = {
    name: "",
};

const useStyles = makeStyles(styles);

const SeasonEdit = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams();
    const seasonid = id;
    // state
    const [formValue, setFormValue] = useState(initialFormValue);
    const { name } = formValue;
    const [ValidateError, setValidationError] = useState({})

    useEffect(() => {
        getSeasonlist()
        //eslint-disable-next-line
    }, [])

    // function
    const handleChange = (e) => {
        const { name, value } = e.target;
        let formData = { ...formValue, ...{ [name]: value } };
        setFormValue(formData);
        //setItem(value)
        setValidationError("")
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        let errors = {}
        if (isEmpty(name)) {
            errors.name = "Season field is required"
            getSeasonlist()
        }
        if (!isEmpty(errors)) {
            return setValidationError(errors)
        }
        let data = {
            id: seasonid,
            category: name,
        };
        const { status, error } = await UpdateCategoryAction(data);
        if (status) {
            toastAlert("success", "Updated successfully", "game add");
            history.push('/seasonlist')
        }
        else {
            toastAlert("error", error, "game add");
        }
    };

    const getSeasonlist = async () => {
        const data = {
            id: seasonid
        }
        const { result } = await EditCategory(data)
        if (result) {
            let formData = { ...formValue, ...{ name: result.category } }
            setFormValue(formData)
        }

    }

    return (
        <div>
            <div className="page_header">
                <Link className="btn btn_custom mr-3 mb-4" to="/seasonlist">Back</Link>
            </div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <form
                            className={classes.form}
                            noValidate
                            onSubmit={handleFormSubmit}
                        >
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Edit Season</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <div className="container">
                                        <div className="row wow fadeIn form-border">
                                            <GridItem xs={12} sm={12} md={12}>
                                                <CustomInput
                                                    labelText="Season"
                                                    name="name"
                                                    value={name}
                                                    onChange={handleChange}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                />
                                                {
                                                    ValidateError && ValidateError.name && <p className={'error-message'}>{ValidateError.name}</p>
                                                }
                                            </GridItem>
                                            <label className="primary_label"> </label>

                                        </div>
                                    </div>
                                </GridContainer>
                            </CardBody>
                            <CardFooter>
                                <Button color="primary" type="submit">
                                    Update
                                </Button>
                            </CardFooter>
                        </form>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
};

export default SeasonEdit;
