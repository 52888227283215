import axios from "axios";

// import action
import { decodeJwt } from "./jsonWebToken";

// import lib
import config from "../lib/config";
import setAuthToken from "../lib/setAuthToken";

import { SET_CURRENT_USER, SET_AUTHENTICATION } from "../constant";

export const getAllcategory = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/v2/routesAdmin/categorylist`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      params: data,
    });

    // localStorage.setItem('admin_token', respData.data.token);

    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const getadminUsers = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesAdmin/adminprofile`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });


    return {
      loading: false,
      result: respData.data.data,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const login = async (data, dispatch) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/v2/routesAdmin/login`,
      data,
    });

    if (respData && respData.data && respData.data.token) {
      localStorage.setItem("admin_token", respData.data.token);
      setAuthToken(respData.data.token);

      decodeJwt(respData.data.token, dispatch);
      return {
        loading: false,
        result: respData.data.result,
      };
    } else {
      return {
        loading: false,
        tfas: respData.data.tfa,
      };
    }
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const logout = (history) => {
  localStorage.removeItem("admin_token");
  history.push("/login");
  setAuthToken("");
};

export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const setAuthReducer = (decoded) => {
  return {
    type: SET_AUTHENTICATION,
    authData: decoded,
  };
};

export const updateProfile = async (data) => {
  try {
    await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesAdmin/updateprofile`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    return {
      loading: false,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};
export const forgotPassword = async (data) => {
  try {

    let respData = await axios({
      'method': 'post',
      'url': `${config.baseUrl}/api/routesAdmin/forgotPassword`,
      data

    });

    return {
      loading: false,
      result: respData.data.result
    }

  }
  catch (err) {
    var sendErr = '';
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr
    }
  }
}

export const changeForgorpassword = async (data) => {
  try {
    let respData = await axios({
      'method': 'post',
      'url': `${config.baseUrl}/api/routesAdmin/resetPassword`,
      data

    });
    return {
      status: "success",
      loading: false,
      message: respData.data.messages
    }
  }
  catch (err) {
    return {
      status: "failed",
      loading: false,
      message: err.response.data.messages,
      error: err.response.data.errors,
    }
  }
}