import React, { useEffect, useState } from 'react';
// import material UI
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from '@material-ui/data-grid';
import { Paper } from '@material-ui/core';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { GetNFTOwnerAction } from 'actions/admin';

import { useParams, Link } from "react-router-dom";
import {
    dateFormat
} from "../../helper/Helper"

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    flexHeader: {
        display: "flex !important",
        justifyContent: "space-between !important",
        alignItems: "center !important"
    },
    btnPrimary: {
        backgroundColor: "#b462c5",
        color: "#ffffff !important",
        padding: "7px 15px",
        fontSize: "12px",
        fontWeight: "700"
    }
};
const useStyles = makeStyles(styles);


const OwnerList = (props) => {
    const classes = useStyles();
    const { id } = useParams();
    // state
    const [data, setData] = useState([])
    const [pageSize, setpageSize] = React.useState(10)
    const columns = ([
        {
            field: 'date', headerName: 'Date', width: 200, valueGetter: (params: GridValueGetterParams) =>
                `${dateFormat(params.row.date, 'DD-MM-YYYY hh:mm A') || ''}`
        },
        { field: 'owneraddress', headerName: 'Owner address', width: 200 },
        { field: 'type', headerName: 'Type', width: 200 },
        { field: 'totalQuantity', headerName: 'Quantity', width: 200 },
        {
            field: 'saleStatus',
            headerName: 'Status',
            width: 200,
            renderCell: (params) => {
                var status = (params && params.row && params.row.saleStatus === "sale") ? "Sale"
                    : (params && params.row && params.row.saleStatus === "adminnotforsale") ? "Not for sale(by admin)"
                        : (params && params.row && params.row.saleStatus === "stop") ? "Stopped"
                            : (params && params.row && params.row.saleStatus === "notforsale") ? "Not for sale(by user)"
                                : ""
                return status;
            }
        },
        {
            field: 'TokenPrice',
            headerName: 'Token price (MATIC)',
            width: 200,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.TokenPrice || ''} `,
        },
    ])

    // function



    useEffect(() => {
        getAllNftLists();
        //eslint-disable-next-line
    }, [])

    const getAllNftLists = async () => {
        const { result } = await GetNFTOwnerAction({ id: id })
        if (result) {
            let respData = result.map((item, key) => {
                return {
                    id: key,
                    ...item
                }
            })
            setData(respData)
        }
        else {
        }
    }
    const handlePageSizeChange = async (params) => {
        setpageSize(params.pageSize)
    }
    return (
        <div className={classes.root}>
            <div className="page_header">
                <Link className="btn btn_custom mr-3 mb-4" to="/mint-NftLists">Back</Link>
            </div>

            <Paper className={classes.paper}>
                <Card>
                    <CardHeader color="primary">
                        <div className={classes.flexHeader}>
                            <h4 className={classes.cardTitleWhite}>NFT Owner Lists</h4>
                            {/* <Link to={'/add'} className={classes.btnPrimary}>Add Admin</Link> */}
                        </div>
                    </CardHeader>
                    <CardBody>
                        {data && data.length > 0 &&
                            <div style={{ height: 600, width: '100%' }}>
                                <DataGrid pageSize={pageSize} pagination rows={data} columns={columns} rowsPerPageOptions={[10, 15]} onPageSizeChange={handlePageSizeChange} />
                            </div>
                        }
                    </CardBody>
                </Card>
            </Paper>
        </div >
    )
}

export default OwnerList;