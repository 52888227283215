let key = {};
var API_URL = "https://nftapi.funtico.com"
if (process.env.REACT_APP_MODE === "production") {

  key = {
    baseUrl: `${API_URL}`,
    singlesmartContract: "0x8d29314E31c29cBD862ea6Beb7c2a57a06BE7Db4",
    multiplesmartContract: "0x01a304b8AF15C548533ddBC05550b2A05Cd898d6",
    token_contract: "0xeb669Bf32778268B21Efc6E92D7445d29A66111d",
    network: 80001,
    infuraurl: "https://matic-mumbai.chainstacklabs.com",
    IPFS_IMG: "https://ipfs.io/ipfs",
    mumbaipolygon: "https://mumbai.polygonscan.com/",
    sitelink: "http://3.121.145.251/",
    cryptoKey: "wownft@$(#&hh234EDHCb",
    cryptoIv: "wownft(*38#56@!hxC",
    owneraddress: "0xacAA5EfB18b0c0A7dd42d3dac7bB6c3Ec6f9891A",
  };

} else {
  //Demo
  // API_URL = "http://localhost:2053";

  key = {
    baseUrl: `${API_URL}`,
    singlesmartContract: "0x8d29314E31c29cBD862ea6Beb7c2a57a06BE7Db4",
    multiplesmartContract: "0x01a304b8AF15C548533ddBC05550b2A05Cd898d6",
    token_contract: "0xeb669Bf32778268B21Efc6E92D7445d29A66111d",
    network: 80001,
    infuraurl: "https://matic-mumbai.chainstacklabs.com",
    IPFS_IMG: "https://ipfs.io/ipfs",
    mumbaipolygon: "https://mumbai.polygonscan.com/",
    sitelink: "http://localhost:3000",
    cryptoKey: "wownft@$(#&hh234EDHCb",
    cryptoIv: "wownft(*38#56@!hxC",
    owneraddress: "0xacAA5EfB18b0c0A7dd42d3dac7bB6c3Ec6f9891A",

  };


}

export default key;
