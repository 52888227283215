// import package
import React, { useState, useEffect, Fragment } from "react";
import { useParams, Link, useHistory } from "react-router-dom";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Checkbox from '@mui/material/Checkbox';
//import avatar from "assets/img/faces/marc.jpg";
import isEmpty from "../../lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";
import FormLabel from '@mui/material/FormLabel';
// import action
import { getCategoryList } from "./../../actions/admin";
import { editNFT, updateNFT } from "../../../src/actions/admin";

import config from "../../lib/config"


const Styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
    },
};

const initialFormValue = {
    tittle: "",
    description: "",
    royalty: "",
    image: "",
    ext: "",
    itemtypevalue: { id: "", value: "" },
    rarityvalue: "",
    seasonvalue: "",
    propertiesList: "",
    gamevalue: "",
    useraddress: "",
    privatekey: ""
};

const useStyles = makeStyles(styles, Styles);

const AdminAdd = () => {

    const classes = useStyles();
    const history = useHistory();
    const { nftId } = useParams();

    // state
    const [formValue, setFormValue] = useState(initialFormValue);
    const {
        tittle,
        description,
        royalty,
    } = formValue;

    const [itemtype, setitemtypes] = useState();
    const [rarity, setRarityLists] = useState();
    const [season, setseasons] = useState();
    const [games, setgames] = useState([]);
    const [allOwners, setallOwners] = useState([]);
    const [selectedOwners, setselectedOwners] = useState({});

    const getallNfttypes = async () => {
        const resp = await getCategoryList()
        if (resp && resp.result) {
            var Itemtypes = resp.result.filter(val => val.categorytype === "Itemtype")
            var Raritys = resp.result.filter(val => val.categorytype === "Rarity")
            var Seasion = resp.result.filter(val => val.categorytype === "Season")
            var Game = resp.result.filter(val => val.categorytype === "Game")

            if (!isEmpty(Itemtypes)) {
                setitemtypes(Itemtypes);
            }
            if (!isEmpty(Raritys)) {
                setRarityLists(Raritys);
            }
            if (!isEmpty(Seasion)) {
                setseasons(Seasion);
            }
            if (!isEmpty(Game)) {
                setgames(Game);
            }
        }
    }
    useEffect(() => {
        getallNfttypes()
        getNFT();
        //eslint-disable-next-line
    }, []);

    async function getNFT() {
        var { result, ownerList } = await editNFT({ id: nftId });
        if (result && result._id) {
            result.ext = (result && result.image && result.image !== "") ? result.image.split(".") : "";
            result.ext = result.ext[1];
            result.image = (result && result.Ipfsimage && result.Ipfsimage !== "") ? config.IPFS_IMG + "/" + result.Ipfsimage : "";

            setFormValue(result)
            setallOwners(ownerList);
            setselectedOwners(ownerList[0]);
        }

    }

    async function handleCheckbox(event, isChecked, value) {
        var status = (isChecked) ? "sale" : "adminnotforsale"
        let formData = { ...selectedOwners, ...{ saleStatus: status } };
        setselectedOwners(formData)
    }

    const handleChangeOwner = (e) => {
        const { value } = e.target;
        console.log(value)
        var index = allOwners.findIndex(val => val.owneraddress === value)
        if (index !== -1) {
            setselectedOwners(allOwners[index]);
        }
    }

    async function updateOwner() {
        var { message } = await updateNFT(selectedOwners);
        if (message === "success") {
            toastAlert("success", "Successfully updated", "updated");
            history.push('/mint-NftLists')
        } else {
            toastAlert("error", "Failed to updated", "updated");
        }
    }

    return (

        <Fragment>
            <div className="page_header">
                <Link className="btn btn_custom mr-3 mb-4" to="/mint-NftLists">Back</Link>
            </div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <form
                            className={classes.form}
                            noValidate
                        >
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Edit NFT</h4>
                            </CardHeader>
                            <CardBody>

                                <div className="container">
                                    <GridContainer className="wow fadeIn form-border">
                                        <GridItem md={3}>

                                            <>
                                                <div>
                                                    <div className="createPreview profileImgPreview">
                                                        <div className="nft__item">
                                                            {formValue.ext == "mp4" ? <video src={formValue.image} name="image" autoPlay playsInLine loop controls alt="profile" height="200px" width="400px" /> : <img
                                                                src={(formValue.image) ? formValue.image : require("../../assets/img/defaultgame.png")} alt="profile"
                                                            ></img>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>

                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem md={6}>
                                            <Box
                                                //component="form"
                                                sx={{
                                                    "& .MuiTextField-root": { m: 0, width: "50ch" },
                                                }}
                                                noValidate
                                                autoComplete="off"
                                            >
                                                <div>
                                                    <TextField
                                                        id="outlined-select-currency"
                                                        select
                                                        label="Select Game"
                                                        value={(formValue && formValue.Game && formValue.Game.value) ? formValue.Game.value : ""}

                                                    >

                                                        {games && games.length > 0 ? games.map((option) => (
                                                            <MenuItem key={option.category} value={option.category}>{option.category}</MenuItem>)) :
                                                            <MenuItem value={'No Data Available'}>  {'No Data Available'}</MenuItem>}

                                                    </TextField>

                                                </div>

                                            </Box>
                                        </GridItem>
                                    </GridContainer>

                                    <GridContainer>
                                        <GridItem md={6}>
                                            <Box
                                                // component="form"
                                                sx={{
                                                    "& .MuiTextField-root": { m: 0, width: "50ch" },
                                                }}
                                                noValidate
                                                autoComplete="off"

                                            >
                                                <div className="mt-3">


                                                    {formValue && formValue.propertiesList &&
                                                        formValue.propertiesList.length > 0 &&
                                                        formValue.propertiesList.map((option) => (
                                                            <h6 className="custom_label">
                                                                {option.label}
                                                                <TextField
                                                                    value={option.value}
                                                                    name={option.label}
                                                                >
                                                                </TextField>
                                                            </h6>
                                                        ))}

                                                </div>
                                            </Box>
                                        </GridItem>

                                    </GridContainer>

                                    <GridContainer>
                                        <GridItem md={6}>
                                            <Box
                                                //component="form"
                                                sx={{
                                                    "& .MuiTextField-root": { m: 0, width: "50ch" },
                                                }}
                                                noValidate
                                                autoComplete="off"
                                            >
                                                <div className="mt-3">
                                                    <TextField
                                                        id="outlined-select-currency"
                                                        select
                                                        label="Item Type"
                                                        value={(formValue && formValue.ItemType && formValue.ItemType.value) ? formValue.ItemType.value : ""}
                                                        name="itemtypevalue"
                                                    >
                                                        {itemtype &&
                                                            itemtype.length > 0 ?
                                                            itemtype.map((option) => (
                                                                <MenuItem key={option.category} id={option.category} value={option.category}>
                                                                    {option.category}
                                                                </MenuItem>
                                                            )) : <MenuItem value={'No Data Available'}>  {'No Data Available'}</MenuItem>}

                                                    </TextField>

                                                </div>
                                            </Box>
                                        </GridItem>
                                    </GridContainer>

                                    <GridContainer>
                                        <GridItem md={6}>
                                            <Box
                                                //component="form"
                                                sx={{
                                                    "& .MuiTextField-root": { m: 0, width: "50ch" },
                                                }}
                                                noValidate
                                                autoComplete="off"
                                            >
                                                <div className="mt-3">
                                                    <TextField
                                                        id="outlined-select-currency"
                                                        select
                                                        label="Select Rarity"
                                                        value={(formValue && formValue.Rarity && formValue.Rarity.value) ? formValue.Rarity.value : ""}
                                                        name="rarityvalue"

                                                    // helperText="Please select Category"
                                                    >
                                                        {rarity &&
                                                            rarity.length > 0 ?
                                                            rarity.map((option) => (
                                                                <MenuItem key={option.category} value={option.category}>
                                                                    {option.category}
                                                                </MenuItem>
                                                            )) : <MenuItem value={'No Data Available'}>  {'No Data Available'}</MenuItem>}

                                                    </TextField>

                                                </div>
                                            </Box>
                                        </GridItem>
                                        <GridItem md={6}>
                                            <Box
                                                sx={{
                                                    "& .MuiTextField-root": { m: 0, width: "50ch" },
                                                }}
                                                noValidate
                                                autoComplete="off"
                                            >
                                                <div className="mt-3">
                                                    <TextField
                                                        id="outlined-select-currency"
                                                        select
                                                        label="Select Owner"
                                                        value={(formValue && formValue.Season && formValue.Season.value) ? formValue.Season.value : ""}
                                                        name="seasonvalue"
                                                    >
                                                        {season &&
                                                            season.length > 0 ?
                                                            season.map((option) => (
                                                                <MenuItem key={option.category} value={option.category}>
                                                                    {option.category}
                                                                </MenuItem>
                                                            )) : <MenuItem value={'No Data Available'}>  {'No Data Available'}</MenuItem>}

                                                    </TextField>

                                                </div>
                                            </Box>
                                        </GridItem>
                                    </GridContainer>

                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CustomInput
                                                labelText="Tittle"
                                                name="tittle"
                                                value={tittle}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                            />

                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CustomInput
                                                labelText="Description"
                                                name="description"
                                                value={description}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                            />

                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CustomInput
                                                labelText="TokenPrice (MATIC)"
                                                name="TokenPrice"
                                                value={(selectedOwners && selectedOwners.TokenPrice) ? selectedOwners.TokenPrice : 0}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                            />

                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CustomInput
                                                labelText="Royalty (%)"
                                                name="royalty"
                                                value={royalty}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                            />

                                        </GridItem>
                                    </GridContainer>

                                    <GridContainer>
                                        <GridItem md={6}>
                                            <Box
                                                sx={{
                                                    "& .MuiTextField-root": { m: 0, width: "50ch" },
                                                }}
                                                noValidate
                                                autoComplete="off"
                                            >
                                                <div className="mt-3">
                                                    <TextField
                                                        id="outlined-select-currency"
                                                        select
                                                        label="Select Owners"
                                                        value={(selectedOwners && selectedOwners.owneraddress && selectedOwners.owneraddress !== "") ? selectedOwners.owneraddress : ""}
                                                        name="selectedOwners"
                                                        onChange={handleChangeOwner}
                                                    >
                                                        {allOwners &&
                                                            allOwners.length > 0 ?
                                                            allOwners.map((option) => (
                                                                <MenuItem key={option.owneraddress} value={option.owneraddress}>
                                                                    {option.owneraddress}
                                                                </MenuItem>
                                                            )) : <MenuItem value={'No Data Available'}>  {'No Data Available'}</MenuItem>}

                                                    </TextField>

                                                </div>
                                            </Box>
                                        </GridItem>
                                    </GridContainer>

                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <Checkbox
                                                title="Show in marketplace"
                                                onChange={handleCheckbox}
                                                checked={(selectedOwners && selectedOwners.saleStatus === "sale") ? true : false}
                                            />
                                            <FormLabel>Show in marketplace</FormLabel>
                                        </GridItem>
                                    </GridContainer>

                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <FormLabel>Status: {
                                                (selectedOwners && selectedOwners.saleStatus === "sale") ? "Sale"
                                                    : (selectedOwners && selectedOwners.saleStatus === "adminnotforsale") ? "Not for sale(by admin)"
                                                        : (selectedOwners && selectedOwners.saleStatus === "stop") ? "Stopped"
                                                            : (selectedOwners && selectedOwners.saleStatus === "notforsale") ? "Not for sale(by user)"
                                                                : ""
                                            }
                                            </FormLabel>
                                        </GridItem>
                                    </GridContainer>

                                </div>

                            </CardBody>
                            <CardFooter>
                                <GridContainer>
                                    <GridItem md={12}>
                                        <div className="pl-3">
                                            <Button color="primary" type="button" onClick={() => updateOwner()}>
                                                Update
                                            </Button>
                                        </div>
                                    </GridItem>
                                </GridContainer>

                            </CardFooter>
                        </form>
                    </Card>
                </GridItem>
            </GridContainer>
        </Fragment>
    );
};

export default AdminAdd;
