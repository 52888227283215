import React, { useEffect } from "react";
import { createBrowserHistory } from "history";
import { Router, Switch, Redirect, Route } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import Admin from "./components/layouts/Admin";
import ConditionRoute from "./components/Route/ConditionRoute";
import store from "./store";
import "assets/css/material-dashboard-react.css?v=1.9.0";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import globalStyle from "./style/global.style";
import { decodeJwt } from "./actions/jsonWebToken";

import LoginPage from "./pages/LoginPage/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import { AdminList, AdminAdd, AdminEdit } from "./pages/Admin";
import editRole from "./pages/Admin/editRole";
import addGame from "./pages/Admin/Game/addGame";
import GameList from "./pages/Admin/Game/GameList";
import GameEdit from "./pages/Admin/Game/GameEdit";
import ItemTypeList from "pages/Admin/ItemType/Itemlist";
import AddItemType from "pages/Admin/ItemType/addItemType";
import ItemTypeEdit from "pages/Admin/ItemType/ItemEdit";
import RarityList from "pages/Admin/Rarity.js/RarityList";
import AddRarity from "pages/Admin/Rarity.js/AddRarity";
import RarityEdit from "pages/Admin/Rarity.js/RarityEdit";
import SeasonList from "pages/Admin/Season/SeasonList";
import AddSeason from "pages/Admin/Season/AddSeason";
import SeasonEdit from "pages/Admin/Season/SeasonEdit";
import EditProfile from "./pages/UserProfile/EditProfile";
import CategoryList from "./pages/Category/CategoryList";
import mintNft from "./pages/Admin/mintNft";
import MintNftLists from "./pages/Admin/mintNftLists";
import TransactionHistory from "./pages/Admin/TransactionHistory"
import viewNft from "./pages/Admin/viewNft";
import ownerList from "./pages/Admin/ownerList";
import roleManagement from "./pages/Bids/Bids";
import ForgotPage from "./pages/ForgotPage/ForgotPassword";
import forgotPassword from "./pages/ForgotPage/changeForgotpassword";
import Userlist from "./pages/Admin/userList"
import Settings from "./pages/Settings/settings"
import AdminFee from "./pages/AdminFee/AdminFee"
import editOwnerList from "./pages/Admin/editOwnerList";

const history = createBrowserHistory();

const App = () => {
  const { isAuth } = store.getState().currentUser;

  useEffect(() => {
    if (isAuth !== true && localStorage.admin_token) {
      decodeJwt(localStorage.admin_token, store.dispatch);
    }
  }, [isAuth]);

  return (
    <Provider store={store}>

      <ThemeProvider theme={globalStyle}>
        <ToastContainer />
        <Router history={history} basename={"/"}>
          <Switch>
            <ConditionRoute path="/login" component={LoginPage} type={"auth"} />
            <ConditionRoute
              path="/dashboard"
              component={Dashboard}
              layout={Admin}
              type={"private"}
              store={store}
            />
            <ConditionRoute
              path="/editprofile"
              component={EditProfile}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/adminManagement"
              component={CategoryList}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/bidlist"
              component={roleManagement}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/settings"
              component={Settings}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/adminfee"
              component={AdminFee}
              layout={Admin}
              type={"private"}
            />

            <ConditionRoute
              exact
              path="/mintNft"
              component={mintNft}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/mint-NftLists"
              component={MintNftLists}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/viewNft/:id"
              component={viewNft}
              layout={Admin}
              type={"public"}
            />
            <ConditionRoute
              exact
              path="/ownerList/:id"
              component={ownerList}
              layout={Admin}
              type={"public"}
            />
            <ConditionRoute
              exact
              path="/edit-owner/:nftId"
              component={editOwnerList}
              layout={Admin}
              type={"public"}
            />
            <ConditionRoute
              exact
              path="/list"
              component={AdminList}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/add"
              component={AdminAdd}
              layout={Admin}
              type={"public"}
            />
            <ConditionRoute
              exact
              path="/eidt/:userId"
              component={AdminEdit}
              layout={Admin}
              type={"public"}
            />
            <ConditionRoute
              exact
              path="/editRole/:userId"
              component={editRole}
              layout={Admin}
              type={"public"}
            />
            <ConditionRoute
              exact
              path="/addGame"
              component={addGame}
              layout={Admin}
              type={"public"}
            />
            <ConditionRoute
              exact
              path="/game-list"
              component={GameList}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              path="/edit-game/:id"
              component={GameEdit}
              layout={Admin}
              type={"public"}
            />


            <ConditionRoute
              exact
              path="/addItemType"
              component={AddItemType}
              layout={Admin}
              type={"public"}
            />
            <ConditionRoute
              exact
              path="/edititem/:userId"
              component={ItemTypeEdit}
              layout={Admin}
              type={"public"}
            />
            <ConditionRoute
              exact
              path="/itemTypeList"
              component={ItemTypeList}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/raritylist"
              component={RarityList}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/addrarity"
              component={AddRarity}
              layout={Admin}
              type={"public"}
            />
            <ConditionRoute
              exact
              path="/editrarity/:id"
              component={RarityEdit}
              layout={Admin}
              type={"public"}
            />
            <ConditionRoute
              exact
              path="/seasonlist"
              component={SeasonList}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/addseason"
              component={AddSeason}
              layout={Admin}
              type={"public"}
            />
            <ConditionRoute
              exact
              path="/editseason/:id"
              component={SeasonEdit}
              layout={Admin}
              type={"public"}
            />
            <ConditionRoute
              path="/forgot"
              component={ForgotPage}
              type={"auth"}
            />
            <ConditionRoute
              path="/forgotpassword/:id"
              component={forgotPassword}
              type={"public"}
            />
            <ConditionRoute
              exact
              path="/transaction-history"
              component={TransactionHistory}
              layout={Admin}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/user-list"
              component={Userlist}
              layout={Admin}
              type={"private"}
            />
            <Redirect from="/" to="/login" />
          </Switch>
        </Router>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
