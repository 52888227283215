// import package
import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

// @material-ui/core 
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, FormControlLabel, InputLabel } from '@material-ui/core';
import {
    CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
    CheckBox as CheckBoxIcon
} from '@material-ui/icons';

// import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { updateRole, roleAdmin } from './../../actions/admin'

// import lib
import routes from '../../routes';
import { toastAlert } from '../../lib/toastAlert';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

const initialFormValue = {
    'role': "",
    'restriction': []  //restriction(path, isWriteAccess)
}

const useStyles = makeStyles(styles);

const AdminEdit = (props) => {
    const classes = useStyles();
    const { userId } = useParams();

    const history = useHistory();
    const [formValue, setFormValue] = useState(initialFormValue);

    const { role, restriction } = formValue;

    // function
    const handleChange = (e) => {
        const { name, value } = e.target;
        let formData = { ...formValue, ...{ [name]: value } }
        setFormValue(formData)
    }

    const handleRestriction = (data) => {
        let index = restriction.findIndex((el => el.path === data.path))
        if (index >= 0) {
            if (data.isRestricted === 'multi') {
                let newData = []
                restriction.filter((el, key) => {
                    let childData = data.child.find((item => item.path === el.path))
                    if (!(childData || key === index)) {
                        newData.push(el)
                    }
                    return true
                })

                let formData = {
                    ...formValue, ...{
                        'restriction': newData
                    }
                }
                setFormValue(formData);
                return
            } else {
                let formData = {
                    ...formValue, ...{
                        'restriction': restriction.filter((el, key) => key !== index && el)
                    }
                }
                setFormValue(formData);
                return
            }
        } else {
            if (data.isRestricted === 'multi') {
                let newRestriction = [{
                    'path': data.path,
                    'isWriteAccess': false
                }]

                data.child.filter(item => {
                    newRestriction.push({
                        'path': item.path,
                        'isWriteAccess': false
                    })
                    return true
                })

                const newRestrictionValue = [
                    ...restriction, ...newRestriction
                ]

                let formData = {
                    ...formValue, ...{
                        'restriction': newRestrictionValue
                    }
                }

                setFormValue(formData);
                return
            } else {
                const newRestriction = [
                    ...restriction, ...[{
                        'path': data.path,
                        'isWriteAccess': false
                    }]
                ]
                let formData = {
                    ...formValue, ...{
                        'restriction': newRestriction
                    }
                }
                setFormValue(formData);
                return
            }
        }
    }
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        let reqData = {
            "adminId": userId,
            role,
            restriction
        }
        try {
            const { status, message } = await updateRole(reqData);

            if (status === true) {
                toastAlert('success', message, 'editAdmin')
            }
            if (status === false) {
                toastAlert('error', message, 'editAdmin')
            }

        } catch (err) {

        }
    }


    const renderRestrictionList = () => {
        let arr = [];
        routes && routes.length > 0 && routes.map((el) => {

            if (el.isSideMenu) {
                if (el.type === 'multi') {

                    el.child.map((item) => {

                        if (item.isRestricted !== 'common') {
                            var isChecked = false;
                            let restrictionData = restriction.find((restItem => restItem.path === item.path));
                            if (restrictionData) {
                                isChecked = true
                            }

                            arr.push(
                                <div key={item.name}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={isChecked}
                                                onClick={() => handleRestriction(item)}
                                            />
                                        }
                                        label={item.name}
                                    />

                                </div>
                            )
                        }

                        return true

                    })

                } else {
                    let isChecked = false;
                    let restrictionData = restriction.find((restItem => restItem.path === el.path));
                    if (restrictionData) {
                        isChecked = true
                    }

                    arr.push(
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={isChecked}
                                        onClick={() => handleRestriction(el)}

                                    />
                                }
                                label={el.name}
                            />

                        </div>
                    )
                }
            }
            return true

        })
        return arr;
    }



    useEffect(() => {
        const fetchAdminDetail = async () => {
            try {
                const { result } = await roleAdmin(userId)
                if (result) {
                    setFormValue(result);
                }

            } catch (err) {

            }
        }
        fetchAdminDetail()

    }, [userId])
    const back = () => {
        history.push('/bidlist')
    }

    return (
        <div>
            <div className="page_header">
                <button className="btn btn_custom mr-3 mb-4" onClick={() => back()}>Back</button>
            </div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <form className={classes.form} noValidate onSubmit={handleFormSubmit}>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Edit Admin Role</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Name"
                                            name="role"
                                            value={userId}
                                            onChange={handleChange}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />

                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <InputLabel>Restriction</InputLabel>
                                        {renderRestrictionList()}

                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            <CardFooter>
                                <Button color="primary" type="submit">Edit</Button>
                            </CardFooter>
                        </form>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}

export default AdminEdit;