import { deleteCategory } from "actions/admin";
import { toastAlert } from "lib/toastAlert";
import React, { useImperativeHandle, forwardRef, useState } from "react";


export const DeleteGame = forwardRef((props, ref) => {
    const [categoryId, setCategoryId] = useState();


    async function removemodelfunction() {
        setTimeout(() => window.$("#place_bid_modal").modal("hide"), 600);
    }
    useImperativeHandle(ref, () => ({
        async Season_Click(categoryId) {
            setCategoryId(categoryId)
            window.$("#place_bid_modal").modal("show");
        },
    }));



    const handleDelete = async () => {
        try {
           
            const iid = categoryId;
            var data = {
                id: iid,
                category:"Game"
            }
            const { status ,message} = await deleteCategory(data);
            
            if (status) {
                
                window.$("#place_bid_modal").modal("hide");
                props.fetchCategory();
                toastAlert('success', message, 'currency')

            } else {
                toastAlert('error', message, 'currency')
            }
        } catch (err) { }
    }


    const cancelDatas = async () => {
        setTimeout(() => window.$("#place_bid_modal").modal("hide"), 100);
    }

    return (
        <div>
            <div
                className="modal fade primary_modal"
                id="place_bid_modal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="place_bid_modalCenteredLabel"
                aria-hidden="true"
                data-backdrop="static"
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-md"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header text-center">


                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={removemodelfunction}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body px-0">
                            <form className="px-4 bid_form">
                                <h5 htmlFor="bid" style={{ fontWeight: "420" }}>Do you want to delete this game?</h5>

                                <div className="row pb-3">
                                    <div className="col-12 col-sm-6">
                                        <button 
                                        style={{background:"#04061f",color:'white'}}
                                            type="button"
                                            className="btn btn"
                                            onClick={() => cancelDatas()}
                                        >
                                            Cancel
                                        </button>&nbsp;&nbsp;&nbsp;
                                        <button
                                        style={{background:"#c7ff31",color:'black'}}
                                            type="button"
                                            className="btn btn"

                                            onClick={() => handleDelete()}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </div>
                                {/* </div> */}

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

