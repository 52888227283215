import React, { useEffect,useState,Fragment } from "react";
// @material-ui/core
import { DataGrid } from '@material-ui/data-grid';
import { Paper } from '@material-ui/core';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import DashboardCount from '../../components/DashboardCount';
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import ReactHighcharts from "react-highcharts";
import {getdashboardchart} from "../../actions/dashboard"
import {convert ,momentFormat,onlydate} from "../../helper/Helper"
import config from "../../lib/config"
import {Gettodaybuylist} from "actions/admin"

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {
  LibraryBooks as LibraryBook,
 
} from '@material-ui/icons';
const useStyles = makeStyles(styles);
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);



export default function Dashboard() {

  
  const [date, setdate] = useState([]);
  const [amount, setamount] = useState([]);
  const [pageSize,setpageSize] = useState(10)
  
 const [data, setData] = useState([])
  const chartDetails = async () => {
  
    var createdAt = [];
    var amount=[]
    const { result } = await getdashboardchart()
    if(result){
      for(let i = 0; i < result.length; i++){
        createdAt.push(onlydate(result[i].parsedDate))
        amount.push(result[i].Amount)
        
      }
    }
    setdate(createdAt)
  
    setamount(amount)
  }


  const chartConfig = {
    title: {
      text: 'Sales'
  },
  yAxis: {        
    title: {
      text: 'Price (Matic)'
  }
},
    xAxis:
     {
      categories: date
    },
    series: [
      { name:"week",
        data: amount
      }
    ],
    tooltip: {
      shared: true,
      formatter: function () {
        return (
          this.x +
          "</b><br/>" +
          this.y+" </b> Matic<br/>"
        );
      },
    },
  };
  const columns = ([
    { field: 'from', headerName: 'From', width: 200,sortable: false,disableColumnMenu: true,},
    { field: 'owner', headerName: 'To', width: 200,sortable: false,disableColumnMenu: true, },
    { field: 'price', headerName: 'Price (Matic)', width: 200,sortable: false,disableColumnMenu: true,
    renderCell: (params) => {
        return (
            convert(params.row.price)
        )
    } },
    { field: 'quantity', headerName: 'Quantity', width: 150,sortable: false,disableColumnMenu: true, },
    { field: 'type', headerName: 'Type', width: 150,sortable: false,disableColumnMenu: true,
    renderCell: (params) => {
        return (
            params.row.type ? params.row.type : '-'
        )
    }
},
{ field: 'date', headerName: 'Date', width: 200 ,sortable: false,disableColumnMenu: true,
renderCell: (params) => {
    return (momentFormat(params.row.date))
    
}
},
    {
        field: 'txid',
        headerName: 'Hash',
        width: 150,sortable: false,disableColumnMenu: true,
        renderCell: (params) => {
            return (
              
                <Fragment>
                   {params.row.txid!==''? <a href={config.mumbaipolygon+'tx/' + params.row.txid} target="_blank" rel="noopener noreferrer">
                   
                    <LibraryBook style={{ color: "#109ebf" }} />
                    </a> : "-" }
                </Fragment>
            )
        }
    },
])
const gettodaybuy = async () => {
  const { result } = await Gettodaybuylist()
  if (result) {
     
      let respData = result.map((item, key) => {
          return {
              id: key,
              ...item
          }
      })
     
      setData(respData)
  }
  else {
  }
}
  useEffect(() => {
    chartDetails()
    gettodaybuy()
  }, []);
  const classes = useStyles();

  const handlePageSizeChange = async (params) => {
    setpageSize(params.pageSize)
}


  return (
    <div>
      <DashboardCount classes={classes} />
      <ReactHighcharts config={chartConfig} />;
      <div className={classes.root}>
            <Paper className={classes.paper}>
                <Card>
                    <CardHeader color="primary">
                        <div className={classes.flexHeader}>
                            <h4 className={classes.cardTitleWhite}>Today purchase</h4>
                        </div>
                    </CardHeader>
                    <CardBody>
                      {data ==='' && 
                      <p className="align-cnenter"> No records</p>}
                        {data && data.length > 0 &&
                            <div style={{ height: 600, width: '100%' }}>
                                <DataGrid pageSize={pageSize} pagination   rowsPerPageOptions={[10, 15]} onPageSizeChange={handlePageSizeChange} rows={data} columns={columns} />
                            </div>
                        }
                    </CardBody>
                </Card>
            </Paper>
        </div >
    </div>
  );
}
